import store from "../store/store";
import { downloadsActionTypes } from "./saga";

/**
 * @module DownloadSaga
 */

/**
 * get profile picture download URL
 * @param {string} profileType
 * @param {string} profileId
 * @param {string} uid
 */
export function getProfilePictureDownloadUrl(profileType, profileId, uid) {
  store.dispatch({
    type: downloadsActionTypes.GET_PROFILE_PICTURE_DOWNLOAD_URL,
    payload: {
      profileType: profileType,
      profileId: profileId,
      uid: uid
    }
  });
}

/**
 * get document download URL
 * @param {string} documentId
 */
export function getDocumentDownloadUrl(documentId) {
  store.dispatch({
    type: downloadsActionTypes.GET_DOCUMENT_DOWNLOAD_URL,
    payload: {
      documentId: documentId
    }
  });
}
