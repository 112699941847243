import React from "react";

const NotificationBadge = ({ count, variant, boxShadow, className }) => {
  const _boxShadow = boxShadow === true ? "box-shadow-default" : " ";
  const size =
    count < 99
      ? "height-and-width-for-double-digit-number"
      : count > 99
      ? "height-and-width-for-triple-digit-number"
      : "height-and-width-for-single-digit-number";
  const _variant =
    variant === "primary"
      ? " font-color-white background-color-primary "
      : variant==="secondary"?"background-color-tertiary font-color-white":"background-white font-color-secondary ";
  return (
    <div
      data-cy={
        ["notification-count-data-cy"]
          ? ["notification-count-data-cy"]
          : "header-notification-count"
      }
      className={`${!!count ? "" : "display-none"} ${
        className ? className : ""
      } height-and-width-14px flex-place-children-page-center ${_boxShadow} border-radius-50-percentage ${_variant}  text-align-center font-size-smaller ${size}`}
    >
      {count > 99 ? (
        <var className="font-style-normal">
          99<sup>+</sup>
        </var>
      ) : (
        count
      )}
    </div>
  );
};
export default NotificationBadge;
