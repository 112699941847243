import store from "../store/store";
import { queryActionTypes } from "./saga";
/**
 * @module QuerySaga
 */

/**
 * create query
 * @param {object} queryData
 * @param {object} navigate
 */
export function createQuery(queryData, navigate) {
  store.dispatch({
    type: queryActionTypes.CREATE_QUERY,
    payload: {
      queryData: queryData,
      navigate: navigate
    }
  });
}
