import React, { useRef, useState, useEffect } from "react";
import infoSvg from "../../assets/Info.svg";
import "./SplittedInputBox.css";
import PropTypes from "prop-types";
import { isPureNumber } from "../../services/validators";

function SplittedInputBox(props) {
  const [prefixValue, setPrefixValue] = useState("");
  const [suffixValue, setSuffixValue] = useState("");
  const [error, setError] = useState("");
  const label = useRef(null);
  const firstInputBox = useRef(null);
  const secondInputBox = useRef(null);
  // set prefix values
  useEffect(() => {
    if (
      props.prefixValue !== null &&
      props.prefixValue !== undefined &&
      props.prefixValue !== prefixValue &&
      String(props.prefixValue).trim().length <= 3
    ) {
      setPrefixValue(props.prefixValue);
    }
    // eslint-disable-next-line
  }, [props.prefixValue]);

  // set suffix values
  useEffect(() => {
    if (
      props.suffixValue !== null &&
      props.suffixValue !== undefined &&
      props.suffixValue !== suffixValue &&
      String(props.suffixValue).trim().length <= 3
    ) {
      setSuffixValue(props.suffixValue);
    }
    // eslint-disable-next-line
  }, [props.suffixValue]);

  // validation
  useEffect(() => {
    if (
      prefixValue !== null &&
      prefixValue !== undefined &&
      suffixValue !== null &&
      suffixValue !== undefined
    ) {
      if (
        `${prefixValue}`.trim().length === 0 &&
        `${suffixValue}`.trim().length === 0
      ) {
        typeof props.isValidInput === "function" && props.isValidInput(false);
        if (error !== "") {
          setError("");
        }
      }
      if (
        `${prefixValue}`.trim().length !== 0 &&
        `${prefixValue}`.trim().length < 2
      ) {
        typeof props.isValidInput === "function" && props.isValidInput(false);
        firstInputBox.current && firstInputBox.current.classList.add("error");
        if (
          !(
            error === props.errorMessage ||
            error === "Please enter a valid value"
          )
        ) {
          setError(
            props.errorMessage
              ? props.errorMessage
              : "Please enter a valid value"
          );
        }
      }
      if (
        `${suffixValue}`.trim().length !== 0 &&
        `${suffixValue}`.trim().length < 2
      ) {
        props.isValidInput && props.isValidInput(false);
        firstInputBox.current && firstInputBox.current.classList.add("error");
        if (
          !(
            error === props.errorMessage ||
            error === "Please enter a valid value"
          )
        ) {
          setError(
            props.errorMessage
              ? props.errorMessage
              : "Please enter a valid value"
          );
        }
      }

      if (
        `${prefixValue}`.trim().length >= 2 &&
        `${suffixValue}`.trim().length >= 2 &&
        isPureNumber(`${prefixValue}`) &&
        isPureNumber(`${suffixValue}`)
      ) {
        props.isValidInput && props.isValidInput(true);
        firstInputBox.current &&
          firstInputBox.current.classList.remove("error");
        if (error !== "") {
          setError("");
        }
      }

      if (!isPureNumber(`${prefixValue}`) || !isPureNumber(`${suffixValue}`)) {
        props.isValidInput && props.isValidInput(false);
        firstInputBox.current && firstInputBox.current.classList.add("error");
        if (
          !(
            error === props.errorMessage ||
            error === "Please enter a valid value"
          )
        ) {
          setError(
            props.errorMessage
              ? props.errorMessage
              : "Please enter a valid value"
          );
        }
      }
      // else {
      //   props.isValidInput && props.isValidInput(true);
      //   firstInputBox.current && firstInputBox.current.classList.remove("error");
      // }
    } else {
      if (typeof props.isValidInput === "function") {
        props.isValidInput(false);
      }

      if (firstInputBox.current) {
        firstInputBox.current.classList.add("error");
      }
      if (
        !(
          error === props.errorMessage || error === "Please enter a valid value"
        )
      ) {
        setError(
          props.errorMessage ? props.errorMessage : "Please enter a valid value"
        );
      }
    }
    // eslint-disable-next-line
  }, [prefixValue, suffixValue]);

  const handelPrefixInputChange = (event) => {
    if (event.target.value.length === 3) {
      setPrefixValue(event.target.value);
      secondInputBox.current.focus();
      typeof props.onPrefixChange === "function" && props.onPrefixChange(event);
    } else if (event.target.value.length < 4) {
      setPrefixValue(event.target.value);
      typeof props.onPrefixChange === "function" && props.onPrefixChange(event);
    }
    // Validator(event);
  };

  const handelSuffixInputChange = (event) => {
    if (event.target.value.length < 4) {
      setSuffixValue(event.target.value);
      typeof props.onSuffixChange === "function" && props.onSuffixChange(event);
    }
    // Validator(event);
  };
  // const Validator = (event) => {
  //   switch (event.target.name) {
  //     case "bloodPressurePrefix":
  //       if (+prefixValue === "NAN" || +prefixValue < 0 || +prefixValue > 200) {
  //         setError("Please enter");
  //         if (typeof props.isValidInput === "function") {
  //           props.isValidInput(false);
  //         }
  //       } else {
  //         setError("");
  //         if (typeof props.isValidInput === "function") {
  //           props.isValidInput(true);
  //         }
  //       }
  //       break;

  //     default:
  //       break;
  //   }
  // };

  return (
    <div
      className={`inherit-parent-width  ${
        props.className ? props.className : ``
      }`}
    >
      <div
        className={`position-relative  display-flex flex-align-items-end height-48px inherit-parent-width  ${
          props.disabled === true ? " background-color-grey " : ""
        } ${props.size === "half" ? "min-width-138px" : "min-width-250px"}`}
      >
        <div className="inherit-parent-width">
          <div className="position-relative inherit-parent-width border-bottom-757575-grey">
            <input
              required={props.required ? props.required : false}
              placeholder="---"
              ref={firstInputBox}
              value={prefixValue}
              name={`${props.name}Prefix`}
              onChange={handelPrefixInputChange}
              className={`splitted-input-box background-transparent splitted-input-box-border-none 
                  font-family-gilroy-medium font-size-medium padding-top-large padding-bottom-default width-40-percentage
                  ${
                    props.disabled === true
                      ? " font-color-secondary disabled"
                      : " font-color-secondary"
                  }
                `}
              readOnly={props.disabled}
              onFocus={() => {
                if (props.disabled === true) return;
                firstInputBox.current &&
                  firstInputBox.current.classList.add("active");
              }}
              onBlur={(event) => {
                firstInputBox.current &&
                  firstInputBox.current.classList.remove("active");
                if (event.target.value.trim().length === 0) {
                  firstInputBox.current.classList.remove("error");
                }
                typeof props.onOutOfFocus === "function" &&
                  props.onOutOfFocus(event);
              }}
              inputMode="numeric"
            />
            <span className="padding-horizontal-default">/</span>
            <input
              required={props.required ? props.required : false}
              placeholder="---"
              ref={secondInputBox}
              value={suffixValue}
              name={`${props.name}Suffix`}
              onChange={handelSuffixInputChange}
              className={`splitted-input-box background-transparent splitted-input-box-border-none 
                  font-family-gilroy-medium font-size-medium padding-top-large padding-bottom-default width-40-percentage
                  ${
                    props.disabled === true
                      ? " font-color-secondary disabled"
                      : " font-color-secondary"
                  }
                `}
              readOnly={props.disabled}
              onFocus={() => {
                if (props.disabled === true) return;
                firstInputBox.current &&
                  firstInputBox.current.classList.add("active");
              }}
              onBlur={(event) => {
                firstInputBox.current &&
                  firstInputBox.current.classList.remove("active");
                if (event.target.value.trim().length === 0) {
                  firstInputBox.current.classList.remove("error");
                }
                typeof props.onOutOfFocus === "function" &&
                  props.onOutOfFocus(event);
              }}
              inputMode="numeric"
            />
            <span className="splitted-input-box-border-bottom"></span>
          </div>
        </div>
        <label
          htmlFor={props.name}
          ref={label}
          className={` font-color-secondary splitted-input-box-label position-absolute font-family-gilroy-medium font-size-medium active ${
            props.labelClassName ? props.labelClassName : ""
          } ${props.disabled ? " disabled " : ""}
                `}
        >
          {props.label}
        </label>
        <InfoCard {...props} />
      </div>
      <p className="font-size-smaller font-color-red">{error}&nbsp;</p>
    </div>
  );
}

const InfoCard = (props) => {
  const [showCard, setShowCard] = useState(false);
  const infoCardRef = useRef(null);

  // handle click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !(infoCardRef.current && infoCardRef.current.contains(event.target))
      ) {
        setShowCard(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoCardRef]);

  return (
    <>
      <img
        src={infoSvg}
        alt="info"
        className="height-width-1em position-absolute right-0 absolute-center-self-vertically"
        onClick={() => setShowCard((prevState) => !prevState)}
        data-cy={`${props.name}-info-btn`}
      />
      {showCard === true && (
        <div
          className="z-index-1 position-absolute right-0 border-radius-default padding-default box-shadow-default 
            font-family-gilroy-regular font-size-smaller font-color-secondary text-transform-uppercase 
            height-fit-content width-95-percentage top-70-percentage background-white
            "
          data-cy={`${props.name}-info-card`}
          ref={infoCardRef}
        >
          {props.infoText}
        </div>
      )}
    </>
  );
};

SplittedInputBox.propTypes = {
  /**
   * prefix value of the input
   */
  prefixValue: PropTypes.number,
  /**
   * suffix value of the input
   */
  suffixValue: PropTypes.number,
  /**
   * callback function which give the input is valid or not as argument . argument return value would be `true` or `false`
   */
  isValidInput: PropTypes.func,
  /**
   * errorMessage
   */
  errorMessage: PropTypes.string,
  /**
   * onChange event of prefix input element
   */
  onPrefixChange: PropTypes.func,
  /**
   * onChange event of suffix input element
   */
  onSuffixChange: PropTypes.func,
  /**
   * class name for the parent element of the component
   */
  className: PropTypes.string,
  /**
   * disabled attribute
   */
  disabled: PropTypes.bool,
  /**
   * size prop sets the min-width of the component
   */
  size: PropTypes.oneOf(["half", "default"]),
  /**
   * name attribute for the input element
   * it will set prefix of name for prefix element and suffix element
   * example: `name="default"` then name would be `defaultPrefix` and `defaultSuffix`
   */
  name: PropTypes.string,
  /**
   * callback function for onBlur event
   */
  onOutOfFocus: PropTypes.func,
  /**
   * class name for the label element
   */
  labelClassName: PropTypes.string,
  /**
   * content for the label element
   */
  label: PropTypes.string,
  /**
   * tooltip content
   */
  infoText: PropTypes.string,
  /**
   * input required prop `true` or `false`
   */
  required: PropTypes.bool
};

SplittedInputBox.defaultProps = {
  prefixValue: null,
  suffixValue: null,
  isValidInput: undefined,
  errorMessage: null,
  onSuffixChange: undefined,
  onPrefixChange: undefined,
  className: "",
  disabled: false,
  size: "default",
  name: "default",
  onOutOfFocus: undefined,
  labelClassName: "",
  label: "",
  infoText: "Test",
  required: false
};

export default SplittedInputBox;
