import Button from "../../front-end-global-components/components/Button/Button";
import abdm_logo from "../../assets/images/logo/abdm_logo.svg";

export default function ConnectABDM(props) {
  return (
    <section
      data-cy="connect-abdm-fallback"
      className="padding-large text-align-center inherit-parent-width display-flex flex-align-items-center flex-direction-column "
    >
      <img
        src={abdm_logo}
        alt="connect ABDM fallback icon"
        draggable={false}
        width="auto"
        height="80"
      />
      <h3 className=" padding-top-large padding-bottom-small font-family-gilroy-medium font-size-medium font-color-secondary">
        Connect with ABDM
      </h3>
      <div className=" font-family-gilroy-regular font-color-secondary font-size-small ">
        Login with or create a new Ayushman Bharat Health Account to view your
        connections and requests.
      </div>
      <Button
        boxShadow={false}
        data-cy="connect-now-button"
        className={" margin-top-large"}
        text="Connect now"
        onClick={props.connectOnClick}
      />
    </section>
  );
}
