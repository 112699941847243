import {
  // initializeApp, getApps,
  getApp
} from "firebase/app";
import {
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
  connectStorageEmulator,
  deleteObject
} from "firebase/storage";
import { lockerSetup } from "../utils/constants";

// export const storage = getStorage(
//   getApps().length === 0
//     ? initializeApp({
//         apiKey: process.env.REACT_APP_API_KEY,
//         authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//         projectId: process.env.REACT_APP_PROJECT_ID,
//         storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//         messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//         appId: process.env.REACT_APP_APP_ID
//       })
//     : getApp()
// );

export const bucketNames = {
  providerIdentityDocuments:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-provider-identity-documents"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-provider-identity-documents"
      : "dev-ninto-provider-identity-documents",
  nintoProfilePictures:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-profile-pictures"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-profile-pictures"
      : "dev-ninto-profile-pictures",
  documentsUpload:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-documents-upload"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-documents-upload"
      : "dev-ninto-documents-upload",
  abdmDocumentsUpload:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-abdm-documents-upload"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-abdm-documents-upload"
      : "dev-abdm-documents-upload",
  prescriptionTemplates:
    process.env.REACT_APP_STAGING === "production"
      ? "prod-ninto-prescription-templates"
      : process.env.REACT_APP_STAGING === "uat"
      ? "uat-ninto-prescription-templates"
      : "dev-ninto-prescription-templates"
};

const switchBucket = (bucketName) => {
  if (process.env.REACT_APP_STAGING === "local") {
    connectStorageEmulator(getStorage(getApp(), bucketName), "localhost", 9199);
  }
  return getStorage(getApp(), bucketName);
};

export async function uploadFile(patientId, fileType, files, metaData) {
  if (!Array.isArray(files)) return;
  const customStorage = switchBucket(`gs://${bucketNames.abdmDocumentsUpload}`);
  let result;
  for (const file of files) {
    const fileName = file.name ? file.name : file.size;
    result = await uploadBytes(
      ref(
        customStorage,
        `documents/${lockerSetup.locker}/${patientId}/${fileType}/${fileName}`
      ),
      file,
      {
        customMetadata: metaData
      }
    );
  }

  return result;
}

export async function deleteFile(storagePath) {
  const slashElementIndex = storagePath.indexOf("/", 12);
  const fileName = storagePath.substring(
    slashElementIndex + 1,
    storagePath.length
  );
  const bucketName = storagePath.substring(0, slashElementIndex);
  const customStorage = switchBucket(bucketName);
  const url = await getDownloadURL(ref(customStorage, fileName));
  const response = deleteObject(ref(customStorage, url));
  return response;
}

export async function uploadProfilePicture(phoneNumber, file) {
  const customStorage = switchBucket(
    `gs://${bucketNames.nintoProfilePictures}`
  );
  const result = await uploadBytes(
    ref(customStorage, `patients/${phoneNumber}/${file.name}`),
    file
  );
  return result;
}

export async function getFileUrl(storageLocation) {
  // reference
  // "gs://karthealth-dev-patient-3wj52g8h4nh9p/test.pdf"
  const slashElementIndex = storageLocation.indexOf("/", 12);
  const fileName = storageLocation.substring(
    slashElementIndex + 1,
    storageLocation.length
  );

  const bucketName = storageLocation.substring(0, slashElementIndex);
  const customStorage = switchBucket(bucketName);
  let url;
  try {
    url = await getDownloadURL(ref(customStorage, fileName));
  } catch (error) {
    if (error.code === "storage/object-not-found") {
      url = null;
    } else throw error;
  }
  return url;
}
