import { analytics } from "./firebase";
import { setUserId, setUserProperties, logEvent } from "firebase/analytics";

export const logUserData = (phoneNumber, uid) => {
  setUserId(analytics, uid);
  setUserProperties(analytics, {
    phoneNumber: phoneNumber,
    buildNumber: process.env.REACT_APP_BUILD_NUM
  });
};

export const logError = (data) => {
  logEvent(analytics, "exception", data);
};
