import React, { useRef, useState, useEffect } from "react";
import "./AudioPlayer.css";
import "../../design-system.css";
import playIcon from "../../assets/Play.svg";
import pauseIcon from "../../assets/Pause.svg";
import { isValidObject } from "../../services/validators";
import PropTypes from "prop-types";

function AudioPlayer(props) {
  const [play, setPlay] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const audio = useRef(null);
  const isGetAudioAndPlay = useRef(false);

  const audioPlayHandler = () => {
    if (
      audio.current &&
      typeof props.src === "string" &&
      props.src.length > 0
    ) {
      if (play === false) {
        audio.current.play();
        setPlay(true);
      } else if (play === true && currentTime > 0) {
        audio.current.pause();
        setPlay(false);
      }
    } else if (typeof props.getDownloadURL === "function") {
      setLoading(true);
      isGetAudioAndPlay.current = true;
      props.getDownloadURL();
    }
  };

  //to audio play method
  useEffect(() => {
    if (
      isGetAudioAndPlay.current === true &&
      audio.current &&
      typeof props.src === "string" &&
      props.src.length > 0
    ) {
      if (play === false) {
        audio.current.play();
        setPlay(true);
      } else {
        audio.current.pause();
        setPlay(false);
      }
      audio.current.onended = () => {
        setPlay(false);
      };
    }
    audio.current.onended = () => {
      setPlay(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src, audio.current]);

  //audio change listerner
  useEffect(() => {
    if (
      audio.current &&
      typeof props.src === "string" &&
      props.src.length > 0
    ) {
      if (isValidObject(audio.current) && audio.current.tagName === "AUDIO") {
        audio.current.addEventListener("timeupdate", function () {
          if (isValidObject(audio.current)) {
            setCurrentTime(Math.ceil(audio.current.currentTime));
          }
        });
        audio.current.addEventListener("durationchange", function () {
          if (
            isValidObject(audio.current) &&
            audio.current.tagName === "AUDIO" &&
            typeof audio.current.duration === "number" &&
            audio.current.duration !== Infinity
          ) {
            if (loading === true) {
              setLoading(false);
            }
            setDuration(Math.ceil(audio.current.duration));
          }
        });
      }
    }
    return () => {
      window.removeEventListener("timeupdate", function () {
        setCurrentTime(0);
      });

      window.removeEventListener("durationchange", function () {
        setDuration(0);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src]);

  return (
    <React.Fragment>
      <audio
        allow="microphone"
        src={props.src ? props.src : ""}
        ref={audio}
        type="audio/mpeg"
        controls
        className="display-none"
      ></audio>
      <div
        className={`flex-center-children-vertically flex-justify-content-space-between inherit-parent-width ${
          props.className && props.className
        }`}
      >
        {loading === true ? (
          <svg
            data-cy="audio-loading"
            className="audio-player-loading-spinner audio-player-loading-spinner-size"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="audio-player-loading-spinner-path"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        ) : (
          <img
            onClick={() => {
              audioPlayHandler();
            }}
            src={play === true ? pauseIcon : playIcon}
            alt="audio-play-control-icon"
            width="auto"
            className="cursor-pointer audio-play-icon-size"
            data-cy={`${play === true ? "pauseIcon" : "playIcon"}`}
          />
        )}

        <input
          data-cy="audio-player-duration-track"
          disabled={props.loading || !props.src || props.src === ""}
          type="range"
          id="seek-slider"
          max={duration}
          className="audio-duration-track margin-left-medium margin-right-medium cursor-pointer"
          value={currentTime}
          onChange={(event) => {
            audio.current.currentTime = event.target.value;
          }}
        />
        <span
          className="font-color-secondary font-size-small white-space-nowWrap audio-duration-content-size font-family-gilroy-regular"
          data-cy="audio-player-duration"
        >
          {currentTime > 0
            ? `${Math.floor(currentTime / 60)}:${
                Math.ceil(currentTime % 60) > 9
                  ? Math.ceil(currentTime % 60)
                  : "0" + Math.ceil(currentTime % 60)
              }`
            : duration > 0
            ? `${Math.floor(duration / 60)}:${
                Math.ceil(duration % 60) > 9
                  ? Math.ceil(duration % 60)
                  : "0" + Math.ceil(duration % 60)
              }`
            : "-:--"}
        </span>
      </div>
    </React.Fragment>
  );
}

AudioPlayer.propTypes = {
  /**
   * Source for the audio player (URL/ file path)
   */
  src: PropTypes.string.isRequired,
  /**
   * Call back function to get audio URL
   */
  getDownloadURL: PropTypes.func,
  /**
   * audio player container class name
   */
  className: PropTypes.string
};

AudioPlayer.defaultProps = {
  src: undefined,
  getDownloadURL: undefined,
  className: ""
};

export default AudioPlayer;
