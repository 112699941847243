import store from "../store/store";
import { documentsActionTypes } from "./saga";
/**
 * @module DocumentsSaga
 */

/**
 * add documents
 * @param {object} documents
 */
export function setDocuments(documents) {
  store.dispatch({
    type: documentsActionTypes.ADD_DOCUMENTS,
    payload: {
      documents: documents
    }
  });
}
/**
 * set dataTransfer
 * @param {object} dataTransfer
 */
export function setDataTransfer(dataTransfer) {
  store.dispatch({
    type: documentsActionTypes.ADD_DATA_TRANSFER,
    payload: {
      dataTransfer: dataTransfer
    }
  });
}

/**
 * upload document
 * @param {object} fileType
 * @param {object} file
 * @param {string} issuedDateTimestamp
 */
export function uploadDocument(data) {
  store.dispatch({
    type: documentsActionTypes.UPLOAD_DOCUMENT,
    payload: {
      data: data
    }
  });
}

/**
 * add symptom
 * @param {object} data
 * @param {object} navigate
 */
export function addSymptom(data, navigate) {
  store.dispatch({
    type: documentsActionTypes.ADD_SYMPTOM,
    payload: {
      data: data,
      navigate: navigate
    }
  });
}

/**
 * update Symptom
 * @param {string} documentId
 * @param {object} data
 * @param {string} navigate
 */
export function updateSymptom(documentId, data, navigate) {
  store.dispatch({
    type: documentsActionTypes.UPDATE_SYMPTOM,
    payload: {
      data: data,
      documentId: documentId,
      navigate: navigate
    }
  });
}

/**
 * set current documentId
 * @param {string} documentId
 */
export function setCurrentDocument(documentId) {
  store.dispatch({
    type: documentsActionTypes.ADD_CURRENT_DOCUMENT,
    payload: {
      documentId: documentId
    }
  });
}

/**
 * set current documentId
 * @param {string} documentId
 */
export function removeCurrentDocument(documentId) {
  store.dispatch({
    type: documentsActionTypes.REMOVE_CURRENT_DOCUMENT,
    payload: {
      documentId: documentId
    }
  });
}

export function addDocumentFilter(type, filterValue) {
  store.dispatch({
    type: documentsActionTypes.ADD_DOCUMENT_FILTER,
    payload: {
      type: type,
      filterValue: filterValue
    }
  });
}

export function removeDocumentFilter(type) {
  store.dispatch({
    type: documentsActionTypes.REMOVE_DOCUMENT_FILTER,
    payload: {
      clear: type
    }
  });
}

/**
 * create vitals action
 * @param {object} vitalsData
 * @param {object} navigate
 */
export function createVitals(vitalsData, navigate) {
  store.dispatch({
    type: documentsActionTypes.CREATE_VITALS,
    payload: {
      vitalsData: vitalsData,
      navigate: navigate
    }
  });
}

/**
 * 8update form
 * @param {object} admissionForm
 * @param {object} documentId
 * @param {object} navigate
 */
export function updateForm(admissionForm, documentId, navigate) {
  store.dispatch({
    type: documentsActionTypes.UPDATE_FORM,
    payload: {
      data: admissionForm,
      navigate: navigate,
      documentId: documentId
    }
  });
}

/**
 * retry dataTransfer
 * @param {object} data
 */
export function retryDataTransfer(data) {
  store.dispatch({
    type: documentsActionTypes.RETRY_DATA_TRANSFER,
    payload: data
  });
}
