import store from "../store/store";
import { fitnessActionTypes } from "./saga";

/**
 * @module FitnessSaga
 */

/**
 * init google API
 * @param {any} payload
 */
export function initializeGoogleApi(payload) {
  store.dispatch({
    type: fitnessActionTypes.INITIALIZE_GOOGLE_API,
    payload: payload
  });
}

/**
 * sign in with google API for fitness
 * @param {any} payload
 */
export function signInWithGoogleForFitness(payload) {
  store.dispatch({
    type: fitnessActionTypes.SIGN_IN_WITH_GOOGLE_FOR_GOOGLE_API,
    payload: payload
  });
}

/**
 * get google fitness data
 * @param {any} payload
 */
export function getGoogleFitnessData(payload) {
  store.dispatch({
    type: fitnessActionTypes.GET_GOOGLEfITNESS_DATA,
    payload: payload
  });
}
