import React, { useEffect, useState } from "react";
import { consentStatus } from "../../utils/constants";
import "./Chip.css";

export const StatusChip = ({ status, onClick }) => {
  const [statusChip, setStatusChip] = useState({ className: "", Text: "" });

  useEffect(() => {
    switch (status) {
      case consentStatus.pending:
        setStatusChip({
          className: " status-pending-background-color margin-top-default  ",
          Text: "Pending"
        });
        return;
      case consentStatus.denied:
        setStatusChip({
          className: " font-color-red status-denied-background-color ",
          Text: "Denied"
        });
        return;
      case consentStatus.expired:
        setStatusChip({
          className: " background-color-secondary font-color-tertiary",
          Text: "Expired"
        });
        return;
      case consentStatus.approved:
        setStatusChip({
          className: " background-color-approved margin-top-default ",
          Text: "Approved"
        });
        return;
      case consentStatus.autoApproved:
        setStatusChip({
          className: " background-color-approved margin-top-default ",
          Text: "Auto-approved"
        });
        return;
      default:
        break;
    }
  }, [status]);

  return (
    <bdi
      onClick={(event) => {
        if (typeof onClick === "function") {
          onClick(event);
        }
      }}
      className={`height-fit-content ${statusChip.className} font-family-gilroy-regular text-transform-capitalize font-size-small padding-right-default padding-left-default padding-bottom-smaller padding-top-smaller border-radius-default `}
    >
      {statusChip.Text}
    </bdi>
  );
};
