import { all, put, takeEvery } from "@redux-saga/core/effects";
import { checkQueryExists, createQuery } from "../../services/database";
import { throwError } from "../../services/error";
import { isValidObject } from "../../utils/validators";
import { setErrorStatus } from "../status/actions";
import store from "../store/store";

export const queryActionTypes = {
  CREATE_QUERY: "CREATE_QUERY"
};

function* createQueryWorker(action) {
  try {
    yield put({
      type: "SET_QUERY_LOADING",
      payload: {
        loading: true
      }
    });
    let queryResponse;
    //Check query already exists
    if (typeof action.payload.queryData?.document?.id === "string") {
      queryResponse = yield checkQueryExists(
        action.payload.queryData.document.id,
        store.getState().auth.data.phoneNumber
      );
    } else {
      queryResponse = false;
    }

    if (queryResponse === true) {
      throwError("custom", "Query already created for this document");
    } else if (queryResponse === false) {
      let profileId;
      //Invalid document upload
      if (
        action.payload.queryData.document &&
        action.payload.queryData.document.id &&
        isValidObject(store.getState().documents?.currentDocument)
      ) {
        profileId = store.getState().documents?.currentDocument.to.id;
      }
      //General query
      else {
        profileId = store.getState().patients.currentProfile;
      }

      const profiles = store.getState().patients.profiles;
      if (typeof profileId === "string" && profiles[profileId]) {
        const data = action.payload.queryData;
        data["fullName"] = profiles[profileId].name;
        data["phoneNumber"] = profiles[profileId].phoneNumber;
        data["profileId"] = profileId;
        data["timestamp"] = Math.floor(Date.now() / 1000);
        data["userType"] = "patient";
        data["id"] = "/patientProfile";
        yield createQuery(data);
        action.payload.navigate("/querysuccess");
      }
    }
  } catch (error) {
    yield put({
      type: "SET_QUERY_LOADING",
      payload: {
        loading: false
      }
    });
    setErrorStatus(error);
  }
}

export default function* queryWatcher() {
  yield all([takeEvery("CREATE_QUERY", createQueryWorker)]);
}
