export function requestsValidation(requests, autoApprovalPolicies) {
  let modifiedRequests = requests;

  let consentData = [];
  let lockerSetupData = [];
  let authorizationData = [];

  requests?.consents?.forEach((data) => {
    if (new Date(data?.permission?.dataEraseAt) < new Date()) {
      consentData.push({ ...data, status: "EXPIRED" });
    } else if (getConsentRequestStatus(data, autoApprovalPolicies)) {
      consentData.push({ ...data, status: "AUTO-APPROVED" });
    } else {
      consentData.push(data);
    }
  });
  requests?.lockerSetups?.forEach((data) => {
    lockerSetupData.push({
      ...data,
      lastUpdated: data.subscription.lastUpdated,
      hiu: data.subscription.hiu
    });
  });

  requests?.authorizations?.forEach((data) => {
    authorizationData.push({
      ...data,
      hiu: { id: data.requester.id, name: data.requester.name }
    });
  });

  modifiedRequests = {
    ...modifiedRequests,
    lockerSetups: lockerSetupData,
    authorizations: authorizationData,
    consents: consentData
  };

  return modifiedRequests;
}

export function getConsentRequestStatus(request, autoApprovalPolicies) {
  const autoApprovalPolicyData = Object.values(autoApprovalPolicies).find(
    (policy) => {
      if (policy.data.isApplicableForAllHIPs) {
        if (
          request?.permission?.dateRange?.from >=
            policy.data.includedSources[0].period.from &&
          request?.permission?.dateRange?.to <=
            policy.data.includedSources[0].period.to &&
          policy.enabled &&
          request.hip
        ) {
          return policy;
        }
      } else {
        if (
          request?.hip &&
          policy.data.includedSources.find(
            (source) =>
              request?.permission?.dateRange?.from >= source.period.from &&
              request?.permission?.dateRange?.to <= source.period.to &&
              source.hip.id === request.hip
          ) &&
          policy.enabled
        ) {
          return policy;
        }
      }
      return null;
    }
  );
  if (autoApprovalPolicyData) {
    return true;
  } else {
    return false;
  }
}
