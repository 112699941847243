import React from "react";
import "./ToggleSwitch.css";
import PropTypes from "prop-types";

function ToggleSwitch(props) {
  return (
    <label
      className=" display-flex flex-align-items-center"
      htmlFor={props.name ? props.name : "toggle-button"}
    >
      <div className="toggle-button-size position-relative">
        <input
          type="checkbox"
          id={props.name ? props.name : "toggle-button"}
          className=" opacity-zero toggle"
          checked={typeof props.checked === "boolean" ? props.checked : null}
          name={props.name ? props.name : "toggle-button"}
          onChange={(event) => {
            if (typeof props.onChange === "function") {
              return props.onChange(event);
            }
          }}
        />
        <span className="toggle-button-slider toggle-button-thumb"></span>
      </div>
      <span className=" padding-left-default font-family-gilroy-regular font-size-medium font-color-secondary">
        {props.label ? props.label : ""}
      </span>
    </label>
  );
}

ToggleSwitch.propTypes = {
  /**
   * callback function for onChange event
   */
  onChange: PropTypes.func,
  /**
   * label for toggle switch button
   */
  label: PropTypes.string,
  /**
   * name attribute for input element
   */
  name: PropTypes.string,
  /**
   * checked attribute of input `true` or `false`
   */
  checked: PropTypes.bool
};

ToggleSwitch.defaultProps = {
  onChange: null,
  name: "toggle-button",
  checked: null,
  label: null
};

export default ToggleSwitch;
