import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";

export async function getFCMToken() {
  try {
    return getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPI_KEY
    })
      .then((currentToken) => {
        if (currentToken) {
          console.error("current token for client: ", currentToken);
        } else {
          console.error(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.error("An error occurred while retrieving token. ", err);
      });
  } catch (e) {
    console.error("getFCMToken error", e);
    return undefined;
  }
}
