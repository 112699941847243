const queryReducer = (
  state = {
    loading: false
  },
  action
) => {
  switch (action.type) {
    case "SET_QUERY_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "RESET":
      return {
        loading: false
      };

    default:
      return state;
  }
};
export default queryReducer;
