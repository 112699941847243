import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../front-end-global-components/components/Button/Button";
import Header from "../../front-end-global-components/components/Header/Header";
import errorBoundaryFallBack from "../../assets/images/errorFallback.svg";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import TextArea from "../../front-end-global-components/components/TextArea/TextArea";
import ChipInputBox from "../../front-end-global-components/components/ChipInputBox/ChipInputBox";
import SplittedInputBox from "../../front-end-global-components/components/SplittedInputBox/SplittedInputBox";
import { validation } from "../../utils/validators";
import { bloodGroupOptions, genderOptions } from "../../utils/constants";
import { updateForm } from "../../redux/documents/actions";

const Forms = (props) => {
  const { documentId } = useParams();
  const [form, setForm] = useState({});
  const [readOnlyForm, setReadOnlyForm] = useState();
  const [bloodPressure, setBloodPressure] = useState({
    bloodPressurePrefix: "",
    bloodPressureSuffix: ""
  });

  const [inValidForm, setInValidForm] = useState(true);

  const toCamelCase = (text) => {
    let _text = text.split(" ");
    for (let i = 0; i < _text.length; i++) {
      if (i !== 0) {
        _text[i] = _text[i].charAt(0).toUpperCase() + _text[i].slice(1);
      }
    }
    _text = _text.join("");
    return _text;
  };

  useEffect(() => {
    if (Array.isArray(props.documents.data?.[props.profile.currentProfile])) {
      //finding the form document in redux store using documentId param
      let currentForm = props.documents.data[props.profile.currentProfile].find(
        (form) => form.documentId === documentId
      );
      if (
        Array.isArray(currentForm?.fields) &&
        currentForm?.fields.findIndex((field) => field.required === true) > -1
          ? currentForm?.fields
              .filter((field) => field.required === true)
              .every((field) => {
                if (!!field?.value) {
                  return true;
                } else {
                  return false;
                }
              })
          : currentForm?.fields.some((field) => {
              if (!!field?.value) {
                return true;
              } else {
                return false;
              }
            })
      ) {
        //if form document field has any valid value then set readOnly true
        setReadOnlyForm(true);
      } else {
        setReadOnlyForm(false);
      }
      if (!!currentForm && Object.keys(currentForm).length > 0) {
        // if (Array.isArray(currentForm?.fields)) {
        //   const bloodPressureIndex = currentForm?.fields.findIndex(
        //     (field) => field.name === "blood pressure" && !!field?.value
        //   );
        //   if (bloodPressureIndex < 0) return;
        //   setBloodPressure({
        //     bloodPressurePrefix:
        //       currentForm?.fields[bloodPressureIndex].value.split("/")[0],
        //     bloodPressureSuffix:
        //       currentForm?.fields[bloodPressureIndex].value.split("/")[1]
        //   });
        // }
        //sets form document
        setForm(JSON.parse(JSON.stringify(currentForm)));
      }
    }
    if (
      readOnlyForm === false &&
      Array.isArray(props.documents.data?.[props.profile.currentProfile])
    ) {
      let recentVitals = props.documents.data[
        props.profile.currentProfile
      ].find((doc) => doc.type === "vitals" && doc.timestamp <= +new Date());
      if (
        Array.isArray(form?.fields) &&
        form.fields.length > 0 &&
        !!props.profile.data?.[props.profile.currentProfile]?.private
      ) {
        let formFields = form.fields;
        for (let index = 0; index < formFields.length; index++) {
          let fieldName = toCamelCase(formFields[index].name);
          if (
            !!props.profile.data[props.profile.currentProfile].private?.[
              fieldName
            ]
          ) {
            formFields[index].value =
              props.profile.data[props.profile.currentProfile].private[
                fieldName
              ];
          }
        }
        if (!!recentVitals && Object.keys(recentVitals).length > 0) {
          for (let index = 0; index < formFields.length; index++) {
            let fieldName = toCamelCase(formFields[index].name);
            if (recentVitals.hasOwnProperty(fieldName)) {
              if (
                formFields[index].name === "blood pressure" &&
                !!recentVitals[fieldName]?.value
              ) {
                setBloodPressure({
                  ...{
                    bloodPressurePrefix:
                      recentVitals.bloodPressure.value.split("/")[0]
                  },
                  ...{
                    bloodPressureSuffix:
                      recentVitals.bloodPressure.value.split("/")[1]
                  }
                });
              }
              const indexOfField = formFields.findIndex(
                (_field) => _field.name === formFields[index].name
              );
              if (indexOfField > -1) {
                formFields.splice(indexOfField, 1, {
                  ...formFields[indexOfField],
                  ...{
                    value: !!recentVitals[fieldName]?.value
                      ? recentVitals[fieldName].value
                      : recentVitals[fieldName]
                  }
                });
              }
            }
          }
        }
        setForm({
          ...form,
          fields: [...formFields]
        });
      }
    }
    // eslint-disable-next-line
  }, [
    props.documents.data,
    props.profile.currentProfile,
    documentId,
    readOnlyForm
  ]);
  const formOnChangeHandler = (event) => {
    if (Array.isArray(form?.fields)) {
      const indexOfCurrentInputElement = form.fields.findIndex(
        (field) => field.name === event.target.name
      );
      if (indexOfCurrentInputElement > -1) {
        if (form.fields[indexOfCurrentInputElement].name === "date of birth") {
          // convert the date to timestamp milliseconds
          let formFields = form.fields;
          formFields.splice(indexOfCurrentInputElement, 1, {
            ...formFields[indexOfCurrentInputElement],
            ...{ value: +new Date(event.target.value) }
          });
          setForm({
            ...form,
            fields: [...formFields]
          });
        } else {
          //sets value in state directly except timestamp
          let formFields = form.fields;
          formFields.splice(indexOfCurrentInputElement, 1, {
            ...formFields[indexOfCurrentInputElement],
            ...{ value: event.target.value }
          });
          setForm({
            ...form,
            fields: [...formFields]
          });
        }
      }
    }
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    const indexOfBloodPressure = form.fields.findIndex(
      (field) => field.name === "blood pressure"
    );
    if (indexOfBloodPressure > -1) {
      // combine blood pressure suffix and prefix
      let formFields = form.fields;
      formFields.splice(indexOfBloodPressure, 1, {
        ...formFields[indexOfBloodPressure],
        ...{
          value: `${bloodPressure.bloodPressurePrefix}/${bloodPressure.bloodPressureSuffix}`
        }
      });
      setForm({
        ...form,
        fields: [...formFields]
      });
    }
    delete form?.documentId;
    props.updateForm(form, documentId, props.navigate);
  };

  useEffect(() => {
    if (Array.isArray(form?.fields)) {
      let validFields = {};
      for (const field of form.fields) {
        if (field.required === true) {
          if (field.name === "blood pressure") {
            if (
              validation(
                "blood pressurePrefix",
                null,
                bloodPressure.bloodPressurePrefix,
                bloodPressure.bloodPressureSuffix
              )?.status === false ||
              validation(
                "blood pressureSuffix",
                null,
                bloodPressure.bloodPressurePrefix,
                bloodPressure.bloodPressureSuffix
              )?.status === false
            ) {
              validFields[field.name] = false;
              continue;
            }
            if (
              validation(
                "blood pressurePrefix",
                null,
                bloodPressure.bloodPressurePrefix,
                bloodPressure.bloodPressureSuffix
              )?.status === true &&
              validation(
                "blood pressureSuffix",
                null,
                bloodPressure.bloodPressurePrefix,
                bloodPressure.bloodPressureSuffix
              )?.status === true
            ) {
              validFields[field.name] = true;
              continue;
            }
          }
          if (
            !!field?.value &&
            validation(
              field.type === "float" ? field.type : field.name,
              field?.value
            )?.status === true
          ) {
            validFields[field.name] = true;
          } else {
            validFields[field.name] = false;
          }
        } else if (field.required === false) {
          if (field.name === "blood pressure") {
            if (
              (!!bloodPressure.bloodPressurePrefix &&
                validation(
                  "blood pressurePrefix",
                  null,
                  bloodPressure.bloodPressurePrefix,
                  bloodPressure.bloodPressureSuffix
                )?.status === false) ||
              (!!bloodPressure.bloodPressureSuffix &&
                validation(
                  "blood pressureSuffix",
                  null,
                  bloodPressure.bloodPressurePrefix,
                  bloodPressure.bloodPressureSuffix
                )?.status === false)
            ) {
              validFields[field.name] = false;
              continue;
            } else {
              validFields[field.name] = null;
              continue;
            }
          }
          if (
            !!field?.value &&
            validation(
              field.name,
              field?.value,
              bloodPressure.bloodPressurePrefix,
              bloodPressure.bloodPressureSuffix
            )?.status === true
          ) {
            validFields[field.name] = true;
          } else if (
            !!field?.value &&
            validation(
              field.name,
              field?.value,
              bloodPressure.bloodPressurePrefix,
              bloodPressure.bloodPressureSuffix
            )?.status === false
          ) {
            validFields[field.name] = false;
          } else {
            validFields[field.name] = null;
          }
        }
      }
      if (
        Object.values(validFields).some((isValid) => isValid === false) ||
        Object.values(validFields).every((isValid) => isValid === null)
      ) {
        setInValidForm(true);
      } else {
        setInValidForm(false);
      }
    }
  }, [
    bloodPressure.bloodPressurePrefix,
    bloodPressure.bloodPressureSuffix,
    form
  ]);
  return (
    <section className=" inherit-parent-height ">
      <Header
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        title={typeof form?.name === "string" ? form?.name : "Form"}
        backButtonOnClick={() => {
          props.navigate(-1);
        }}
      />
      {!!form && Object.keys(form).length === 0 ? (
        <FormNotFound navigate={props.navigate} />
      ) : props.documents.loading === true ? (
        <FormSuspense />
      ) : readOnlyForm === false ? (
        <section className=" remaining-body-height max-width-588px margin-horizontal-auto overflow-hidden">
          {
            <form
              data-cy="update-form"
              className=" inherit-parent-height overflow-hidden flex-direction-column flex-justify-content-space-between "
              onChange={(event) => {
                formOnChangeHandler(event);
              }}
              onSubmit={(event) => {
                formSubmitHandler(event);
              }}
            >
              <ul className=" overflow-y-auto list-style-none padding-top-large ">
                {Array.isArray(form?.fields) &&
                  form.fields.map((field, index) => (
                    <li
                      key={field.name}
                      className=" padding-left-large padding-right-large "
                    >
                      {field.name === "gender" ||
                      field.name === "blood group" ? (
                        <NativeSelect
                          labelClassName="letter-spacing-4-percentage"
                          className=""
                          selectClassName="text-transform-capitalize"
                          optionsClassName="text-transform-capitalize"
                          name={field.name}
                          label={field.name}
                          data-cy={field.name}
                          defaultValue={form.fields[index]?.value}
                          options={
                            field.name === "gender"
                              ? genderOptions
                              : bloodGroupOptions
                          }
                          required={field.required}
                        />
                      ) : field.name === "allergies" ? (
                        <ChipInputBox
                          className="padding-top-medium padding-bottom-medium"
                          name="allergies"
                          label="allergies"
                          notes="Type and press Enter to Add allergies"
                          chips={form.fields[index].value}
                          onChipAdded={(chips) => {
                            let fields = form.fields;
                            fields.splice(index, 1, {
                              ...fields[index],
                              ...{ value: chips }
                            });
                            setForm({
                              ...form,
                              ...{
                                fields: fields
                              }
                            });
                          }}
                        />
                      ) : field.name === "blood pressure" ? (
                        <SplittedInputBox
                          required={field.required}
                          label={`${field.name} (mmHg)`}
                          prefixValue={bloodPressure.bloodPressurePrefix}
                          suffixValue={bloodPressure.bloodPressureSuffix}
                          name={field.name}
                          size="half"
                          infoText="the systolic pressure first (top) and the diastolic pressure second (below). Ex: 120/80"
                          onPrefixChange={(event) => {
                            // if(event.target.value.trim().length===0) return
                            setBloodPressure({
                              ...bloodPressure,
                              bloodPressurePrefix: event.target.value
                            });
                          }}
                          onSuffixChange={(event) => {
                            // if(event.target.value.trim().length===0) return
                            setBloodPressure({
                              ...bloodPressure,
                              bloodPressureSuffix: event.target.value
                            });
                          }}
                        />
                      ) : field.name === "date of birth" ||
                        field.name === "heart rate" ||
                        field.type === "float" ||
                        field.type === "timestamp" ||
                        field.name === "blood oxygen" ||
                        field.name === "blood sugar" ||
                        field.name === "body temperature" ||
                        field.name === "height" ||
                        field.name === "weight" ? (
                        <InputBox
                          className="padding-top-medium padding-bottom-medium"
                          type={
                            field.type === "float"
                              ? "number"
                              : field.type === "timestamp"
                              ? "date"
                              : "text"
                          }
                          label={field.name}
                          name={field.name}
                          data-cy={field.name}
                          value={
                            field.type === "timestamp" &&
                            !!form?.fields[index]?.value
                              ? new Date(form?.fields[index]?.value)
                                  .toISOString()
                                  .split("T")[0]
                              : form?.fields[index]?.value
                          }
                          required={field.required}
                          validation={(value) =>
                            validation(
                              field.type === "float" ? field.type : field.name,
                              value
                            )
                          }
                        />
                      ) : (
                        <TextArea
                          data-cy={field.name}
                          className=" padding-bottom-medium"
                          name={field.name}
                          label={field.name}
                          required={field.required}
                          value={form?.fields[index]?.value}
                        />
                      )}
                    </li>
                  ))}
              </ul>
              <div className=" padding-left-large padding-right-large padding-top-default ">
                <Button
                  loading={props.documents.loading}
                  data-cy="form-submit-button"
                  type="submit"
                  text="Submit"
                  disabled={inValidForm}
                  boxShadow={false}
                  className="margin-bottom-larger"
                />
              </div>
            </form>
          }
        </section>
      ) : (
        Array.isArray(form?.fields) && (
          <section
            className=" remaining-body-height padding-left-large padding-right-large overflow-y-auto padding-top-medium "
            data-cy="view-filled-form"
          >
            {form.fields.map((field) => (
              <React.Fragment key={field.name}>
                <InputField name={field.name} value={field.value} />
              </React.Fragment>
            ))}
          </section>
        )
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  documents: state.documents
});

const mapDispatchToProps = () => {
  return {
    updateForm: (formData, documentId, navigate) =>
      updateForm(formData, documentId, navigate)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Forms);

function FormNotFound(props) {
  return (
    <section className=" remaining-body-height max-width-588px margin-horizontal-auto overflow-hidden ">
      <section className=" inherit-parent-height inherit-parent-width flex-place-children-page-center padding-larger">
        <main className="flex-place-children-page-center">
          <img src={errorBoundaryFallBack} alt="querySubmittedSuccess" />
          <h1 className="font-weight-normal font-family-gilroy-bold font-size-medium font-color-secondary padding-top-large padding-bottom-large">
            Form not found
          </h1>
          <p className="font-family-gilroy-medium font-size-medium font-color-tertiary text-align-center">
            Please try again later. If the problem persists, please contact
            customer support.
          </p>
          <div className="max-width-35-percentage">
            <Button
              buttonKey="document-retry-button"
              data-cy="button"
              type="submit"
              text="Okay"
              className="inherit-parent-width margin-top-larger"
              onClick={(_) => {
                props.navigate("/");
              }}
              boxShadow={false}
            />
          </div>
        </main>
      </section>
    </section>
  );
}

const getDate = (timestamp) => {
  if (typeof timestamp !== "number") return;
  return `${new Date(timestamp).getDate()}-${
    new Date(timestamp).getMonth() + 1
  }-${new Date(timestamp).getFullYear()}`;
};

const InputField = (field) => {
  return (
    <section className=" font-color-secondary font-family-gilroy-regular inherit-parent-width padding-top-medium padding-bottom-medium margin-horizontal-auto max-width-588px ">
      <div className=" letter-spacing-4-percentage font-size-smaller padding-bottom-small text-transform-uppercase font-weight-400 ">
        {field.name}
      </div>
      <div className=" letter-spacing-4-percentage font-size-medium padding-top-small text-transform-capitalize font-weight-400 ">
        {!!field?.value
          ? field.name === "date of birth" || field.type === "timestamp"
            ? getDate(field.value)
            : Array.isArray(field.value) && field.value.length > 0
            ? field.value.map(
                (val, index) =>
                  val + `${index !== field.value.length - 1 ? ", " : ""}`
              )
            : field.value
          : "-"}
      </div>
    </section>
  );
};

const FormSuspense = () => {
  return (
    <section
      className=" remaining-body-height padding-left-large padding-right-large overflow-y-auto padding-top-medium "
      data-cy="view-filled-form"
    >
      {[...Array(10)].map((val, index) => (
        <span
          key={index}
          className=" padding-left-large padding-right-large margin-vertical-larger"
        >
          <div className=" shimmer width-30-percentage padding-small margin-vertical-default border-radius-default"></div>
          <div className=" shimmer width-90-percent padding-default border-radius-default margin-bottom-large"></div>
        </span>
      ))}
    </section>
  );
};
