import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../front-end-global-components/components/Button/Button";
import Header from "../../front-end-global-components/components/Header/Header";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import SplittedInputBox from "../../front-end-global-components/components/SplittedInputBox/SplittedInputBox";
import {
  isInteger,
  isPureNumber
} from "../../front-end-global-components/services/validators";
import { createVitals } from "../../redux/documents/actions";

const Vitals = (props) => {
  const [isValidVitals, setIsValidVitals] = useState(false);
  const [vitalsFormData, setVitalsFormData] = useState({
    date: new Date().toISOString().split("T")[0],
    time: new Date().toTimeString().slice(0, 5),
    heartRate: "",
    bloodOxygen: "",
    bloodSugar: "",
    bodyTemperature: "",
    height: "",
    weight: "",
    bloodPressurePrefix: "",
    bloodPressureSuffix: ""
  });

  const timevalidation = (value, date) => {
    let result = {};
    if (
      +new Date(date) === +new Date(new Date().toISOString().split("T")[0]) &&
      +new Date(`${new Date().toISOString().split("T")[0]} ${value}`) >
        +new Date()
    ) {
      result = {
        status: false,
        message: "Please enter a valid time"
      };
    } else {
      result = {
        status: true,
        message: null
      };
    }
    return result;
  };

  //vitals submit handler
  const vitalsSubmitHandler = () => {
    let sortedData = {};
    for (const key in vitalsFormData) {
      if (String(vitalsFormData[key]).trim().length === 0) {
        continue;
      }
      if (key === "bodyTemperature" || key === "height" || key === "weight") {
        sortedData = {
          ...sortedData,
          [key]: parseFloat((+vitalsFormData[key]).toFixed(2))
        };
      } else {
        sortedData = {
          ...sortedData,
          [key]: parseInt(vitalsFormData[key])
        };
      }
    }
    sortedData.timestamp = +new Date(
      `${vitalsFormData.date}T${vitalsFormData.time}`
    );
    if (!!sortedData.bloodPressurePrefix && !!sortedData.bloodPressureSuffix) {
      sortedData.bloodPressure = `${parseInt(
        vitalsFormData["bloodPressurePrefix"]
      )}/${parseInt(vitalsFormData["bloodPressureSuffix"])}`;
    }
    delete sortedData.date;
    delete sortedData["bloodPressurePrefix"];
    delete sortedData["bloodPressureSuffix"];
    delete sortedData.time;
    props.createVitals(sortedData, props);
  };

  useEffect(() => {
    let numberOfValidVitals = 0;
    for (const [key, value] of Object.entries(vitalsFormData)) {
      if (
        (key === "date" || key === "time") &&
        validation(key, value).status === false
      ) {
        numberOfValidVitals = 0;
        break;
      }
      if (!!value && validation(key, value).status === false) {
        numberOfValidVitals = 0;
        break;
      }
      if (
        key !== "date" &&
        key !== "time" &&
        !!value &&
        validation(key, value).status === true
      ) {
        numberOfValidVitals++;
      }
    }
    if (numberOfValidVitals > 0) {
      setIsValidVitals(true);
    } else {
      setIsValidVitals(false);
    }
    // eslint-disable-next-line
  }, [vitalsFormData]);

  const validation = (key, value) => {
    switch (key) {
      case "date": {
        if (
          !!value &&
          new Date().getFullYear() - new Date(value).getFullYear() <= 100
        ) {
          return {
            status: true
          };
        }
        return {
          status: false,
          message: "Please enter a valid date"
        };
      }
      case "time": {
        if (!!value) {
          return {
            status: true
          };
        }
        return {
          status: false,
          message: "Please enter a valid time"
        };
      }
      case "heartRate": {
        if (isInteger(value) && value > 0 && value <= 270) {
          return {
            status: true
          };
        }
        return {
          status: false,
          message: "Please enter a valid Heart rate"
        };
      }
      case "bloodOxygen": {
        if (isInteger(value) && value > 0 && value <= 100) {
          return {
            status: true
          };
        }
        return {
          status: false,
          message: "Please enter a valid Oxygen level"
        };
      }
      case "bodyTemperature": {
        if (isPureNumber(value) && value > 0 && value <= 200) {
          return {
            status: true
          };
        }
        return {
          status: false,
          message: "Please enter a valid body temperature"
        };
      }
      case "height": {
        if (isPureNumber(value) && value > 30 && value <= 270) {
          return {
            status: true
          };
        }
        return {
          status: false,
          message: "Please enter a valid body height"
        };
      }
      case "weight": {
        if (isPureNumber(value) && value > 0 && value <= 650) {
          return {
            status: true
          };
        }
        return {
          status: false,
          message: "Please enter a valid body weight"
        };
      }
      case "bloodSugar": {
        if (isInteger(value) && value > 50 && value <= 999) {
          return {
            status: true
          };
        }
        return {
          status: false,
          message: "Please enter a valid blood sugar level"
        };
      }
      case "bloodPressurePrefix": {
        if (
          isInteger(value) &&
          value >= 10 &&
          value <= 999 &&
          isInteger(vitalsFormData.bloodPressureSuffix) &&
          vitalsFormData.bloodPressureSuffix >= 10 &&
          vitalsFormData.bloodPressureSuffix <= 999
        ) {
          return {
            status: true
          };
        }
        return {
          status: false,
          message: "Please enter a valid blood pressure level"
        };
      }
      case "bloodPressureSuffix": {
        if (
          isInteger(value) &&
          value >= 10 &&
          value <= 999 &&
          isInteger(vitalsFormData.bloodPressurePrefix) &&
          vitalsFormData.bloodPressurePrefix >= 10 &&
          vitalsFormData.bloodPressurePrefix <= 999
        ) {
          return {
            status: true
          };
        }
        return {
          status: false,
          message: "Please enter a valid blood pressure level"
        };
      }
      default:
        break;
    }
  };

  return (
    <div className="inherit-parent-height">
      <Header
        title="Add vitals"
        backButtonOnClick={() => {
          props.navigate(-1);
        }}
      />
      <form
        onSubmit={(event) => {
          event.preventDefault();
          vitalsSubmitHandler();
        }}
        className="padding-top-large padding-bottom-large padding-left-larger padding-right-larger max-width-630px margin-horizontal-auto remaining-body-height overflow-y-auto display-flex flex-direction-column flex-justify-content-space-between"
      >
        <section>
          <section className="flex-justify-content-space-between padding-bottom-large">
            <InputBox
              size="small"
              label="Date"
              name="date"
              type="date"
              data-cy="vitals-date"
              className="padding-right-medium"
              onChange={(event) => {
                setVitalsFormData({
                  ...vitalsFormData,
                  date: event.target.value
                });
              }}
              value={vitalsFormData?.date}
              autoFocus={false}
              validation={(value) => timevalidation(vitalsFormData.time, value)}
            />
            <InputBox
              size="small"
              label="Time"
              name="time"
              type="time"
              data-cy="vitals-time"
              className="padding-left-medium"
              value={vitalsFormData?.time}
              onChange={(event) => {
                setVitalsFormData({
                  ...vitalsFormData,
                  time: event.target.value
                });
              }}
              autoFocus={false}
              required={false}
              validation={(value) => timevalidation(value, vitalsFormData.date)}
            />
          </section>
          <section className="flex-justify-content-space-between padding-bottom-large">
            <InputBox
              size="small"
              label="Heart rate (BPM)"
              name="heartRate"
              type="number"
              data-cy="vitals-heart-rate"
              className="padding-right-medium"
              value={vitalsFormData?.heartRate}
              autoFocus={false}
              onChange={(event) => {
                setVitalsFormData({
                  ...vitalsFormData,
                  heartRate: event.target.value
                });
              }}
              validation={(value) => validation("heartRate", value)}
            />
            <InputBox
              size="small"
              label="Body temperature (°F)"
              name="bodyTemperature"
              type="number"
              data-cy="vitals-body-temperature"
              className="padding-left-medium"
              value={vitalsFormData.bodyTemperature}
              onChange={(event) => {
                setVitalsFormData({
                  ...vitalsFormData,
                  bodyTemperature: event.target.value
                });
              }}
              validation={(value) =>
                validation("bodyTemperature", vitalsFormData.bodyTemperature)
              }
              autoFocus={false}
            />
          </section>
          <section className="flex-justify-content-space-between padding-bottom-large">
            <SplittedInputBox
              label="Blood pressure (mmHg)"
              prefixValue={vitalsFormData.bloodPressurePrefix}
              suffixValue={vitalsFormData.bloodPressureSuffix}
              name="bloodPressure"
              size="half"
              infoText="the systolic pressure first (top)
              and the diastolic pressure
              second (below). Ex: 120/80"
              onPrefixChange={(event) => {
                // if(event.target.value.trim().length===0) return
                setVitalsFormData({
                  ...vitalsFormData,
                  bloodPressurePrefix: event.target.value
                });
              }}
              onSuffixChange={(event) => {
                // if(event.target.value.trim().length===0) return
                setVitalsFormData({
                  ...vitalsFormData,
                  bloodPressureSuffix: event.target.value
                });
              }}
            />
            <InputBox
              size="small"
              label="SPO2 - Blood oxygen (%)"
              name="bloodOxygen"
              type="number"
              data-cy="vitals-blood-oxygen"
              className="padding-left-medium"
              value={vitalsFormData?.bloodOxygen}
              onChange={(event) => {
                setVitalsFormData({
                  ...vitalsFormData,
                  bloodOxygen: event.target.value
                });
              }}
              validation={(value) => validation("bloodOxygen", value)}
              autoFocus={false}
            />
          </section>
          <section className="flex-justify-content-space-between padding-bottom-large">
            <InputBox
              size="small"
              label="Height (CM)"
              name="height"
              type="number"
              data-cy="vitals-height"
              className="padding-right-medium"
              value={vitalsFormData?.height}
              autoFocus={false}
              onChange={(event) => {
                setVitalsFormData({
                  ...vitalsFormData,
                  height: event.target.value
                });
              }}
              validation={(value) => validation("height", value)}
            />

            <InputBox
              size="small"
              label="Weight (KG)"
              name="weight"
              type="number"
              data-cy="vitals-weight"
              className="padding-left-medium"
              value={vitalsFormData?.weight}
              autoFocus={false}
              onChange={(event) => {
                setVitalsFormData({
                  ...vitalsFormData,
                  weight: event.target.value
                });
              }}
              validation={(value) => validation("weight", value)}
            />
          </section>
          <InputBox
            size="small"
            label="Blood sugar (fasting)(mg/dL/L)"
            name="bloodSugar"
            type="number"
            data-cy="vitals-blood-sugar"
            value={vitalsFormData?.bloodSugar}
            autoFocus={false}
            onChange={(event) => {
              setVitalsFormData({
                ...vitalsFormData,
                bloodSugar: event.target.value
              });
            }}
            validation={(value) => validation("bloodSugar", value)}
          />
        </section>
        <Button
          loading={props.documents.loading}
          data-cy="vitals-add-button"
          boxShadow={false}
          text="Add vitals"
          type="submit"
          disabled={!isValidVitals}
        />
      </form>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  documents: state.documents
});

const mapDispatchToProps = function (params) {
  return {
    createVitals: (data, history) => createVitals(data, history)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vitals);
