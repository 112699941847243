import { fetchAndActivate, getAll } from "firebase/remote-config";
import { useRef } from "react";
import { useEffect } from "react";
import { remoteConfig } from "./firebase";

async function fallbackRemoteConfig(timeout) {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        experimental: false
      });
    }, timeout);
  });
}

export async function activateRemoteConfig() {
  try {
    let result = {};
    remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 60;
    remoteConfig.defaultConfig = {
      experimental: false
    };
    if (window.Cypress) {
      return (remoteConfig.defaultConfig = {
        experimental: true
      });
    } else {
      await fetchAndActivate(remoteConfig);

      const getAllResult = getAll(remoteConfig);

      for (const configKey in getAllResult) {
        result[configKey] = getAllResult[configKey].asBoolean();
      }
      return result;
    }
  } catch (error) {
    console.error("activateRemoteConfig Error : ", error);
    return null;
  }
}

export const useRemoteConfig = (onConfigChange, isAuthed) => {
  const currentConfig = useRef(null);

  useEffect(() => {
    if (!isAuthed) {
      return;
    } else {
      Promise.any([activateRemoteConfig(), fallbackRemoteConfig(5000)])
        .then((result) => {
          if (result?.experimental !== currentConfig.current?.experimental) {
            currentConfig.current = result;
            onConfigChange(result);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line
  }, [isAuthed]);
};
