import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import useVoiceRecorder from "../../services/useVoiceRecorder";
import startIcon from "../../assets/icons/Record.svg";
import closeIcon from "../../assets/icons/closeIconGreen.svg";
import stopIcon from "../../assets/icons/Stop.svg";
import "./Symptom.css";
import {
  isProfilesLoaded,
  isValidArray,
  isValidObject
} from "../../utils/validators";
import { InputSlider } from "../../front-end-global-components/components/InputSlider/InputSlider";
import AudioPlayer from "../../front-end-global-components/components/AudioPlayer/AudioPlayer";
import Header from "../../front-end-global-components/components/Header/Header";
import SymptomsRenderer from "../../front-end-global-components/components/SymptomRenderer/SymptomRenderer";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import TextArea from "../../front-end-global-components/components/TextArea/TextArea";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { setErrorStatus } from "../../redux/status/actions";
import { getDocumentDownloadUrl } from "../../redux/downloads/actions";
import {
  addSymptom,
  removeCurrentDocument,
  setCurrentDocument,
  updateSymptom
} from "../../redux/documents/actions";
import Button from "../../front-end-global-components/components/Button/Button";

const timeValidation = (value, date) => {
  let result = {};
  if (
    +new Date(date) === +new Date(new Date().toISOString().split("T")[0]) &&
    +new Date(`${new Date().toISOString().split("T")[0]} ${value}`) >
      +new Date()
  ) {
    result = {
      status: false,
      message: "Please enter a valid time"
    };
  } else {
    result = {
      status: true,
      message: null
    };
  }
  return result;
};

const Symptom = (props) => {
  let { documentId } = useParams();
  const [isValidInput, setIsValidInput] = useState(true);
  const [show, setShow] = useState("describe");
  const [addSymptom, setAddSymptom] = useState(true);
  const [updateAudio, setUpdateAudio] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState({
    audioBlob: false,
    audioURL: ""
  });
  // eslint-disable-next-line no-unused-vars
  const [, setRerender] = useState("");
  const [voiceRecorderControl, setVoiceRecorderControl] = useState(null);
  const { startRecording, saveRecording } = useVoiceRecorder();
  const [formData, setFormData] = useState({
    date: new Date().toISOString().split("T")[0],
    time: new Date().toTimeString().slice(0, 5),
    pid: null,
    audio: "",
    timestamp: +new Date(),
    description: "",
    severityScale: "1",
    location: {
      front: [],
      back: []
    },
    type: "symptom"
  });

  const cropper = useRef(null);
  const symptomImageInputFieldRef = useRef(null);
  const addPicturesElementRef = useRef(null);
  const addPicturesSectionWidthRef = useRef(null);
  const [fileToBeCropped, setFileToBeCropped] = useState("");
  const [selectedFileToViewIndex, setSelectedFileToViewIndex] = useState(null);
  const [addedFiles, setAddedFiles] = useState([]);
  const [cropperReady, setCropperReady] = useState(false);

  //Form onchange handler to save form data in state
  const formOnChangeHandler = (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    if (
      documentId &&
      isValidObject(props.documents.data) &&
      isValidArray(props.documents.data[props.profile.currentProfile]) &&
      props.documents.data[props.profile.currentProfile].some(
        (doc) => doc.documentId === documentId
      )
    ) {
      setAddSymptom(false);
      props.getDocumentUrl(documentId);
      props.setCurrentDocument(documentId);
    } else {
      setAddSymptom(true);
    }
    return () => {
      props.removeCurrentDocument();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, props.documents.data]);

  useEffect(() => {
    if (
      addSymptom === false &&
      documentId !== undefined &&
      documentId !== null &&
      isValidObject(props.documents.data) &&
      isValidArray(props.documents.data[props.profile.currentProfile])
    ) {
      const symptomDocument = props.documents.data[
        props.profile.currentProfile
      ].find((symptom) => symptom.documentId === documentId);
      if (isValidObject(symptomDocument)) {
        let obj = {
          documentId: symptomDocument.documentId,
          timestamp: symptomDocument.timestamp,
          pid: symptomDocument.to.id,
          audio:
            isValidObject(symptomDocument.url) &&
            typeof symptomDocument.url.audio === "string"
              ? symptomDocument.url.audio
              : "",
          description: symptomDocument.description,
          severityScale: String(symptomDocument.severityScale),
          location: symptomDocument.location,
          date: new Date(
            symptomDocument.timestamp - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0],
          time: new Date(symptomDocument.timestamp).toTimeString().slice(0, 5),
          url: symptomDocument.url
        };
        setFormData(obj);
        if (obj.audio.length > 0) {
          setVoiceRecorderControl("stop");
        }
      }
    }
  }, [
    props.profile.currentProfile,
    addSymptom,
    documentId,
    props.documents.data
  ]);

  useEffect(() => {
    if (
      isValidObject(props.downloads.data.symptoms) &&
      isValidObject(props.downloads.data.symptoms[documentId]) &&
      isValidArray(props.downloads.data.symptoms[documentId].images)
    ) {
      setAddedFiles([...props.downloads.data.symptoms[documentId].images]);
    }
    // eslint-disable-next-line
  }, [props.downloads.data.symptoms, documentId, props.documents.data]);

  //voice recorder control handler
  const voiceRecorderHandler = async () => {
    if (voiceRecorderControl === null || voiceRecorderControl === "cancel") {
      setVoiceRecorderControl("start");
      const res = await startRecording();
      if (res === false) {
        /**
         * voice recorder returns false is microphone is detected or any other issues in recording
         */
        setVoiceRecorderControl(null);
      }
    } else if (voiceRecorderControl === "start") {
      setVoiceRecorderControl("stop");
      const resBlob = await saveRecording();
      setRecordedAudio({
        audioBlob: resBlob,
        audioURL: URL.createObjectURL(resBlob)
      });
    } else if (voiceRecorderControl === "stop") {
      if (addSymptom === false) {
        setUpdateAudio(true);
        setFormData({ ...formData, audio: "" });
      }
      setVoiceRecorderControl("cancel");
      setRecordedAudio({
        audioBlob: false,
        audioURL: ""
      });
    }
  };

  const symptomSubmitHandler = (event) => {
    event.preventDefault();
    if (addSymptom === true) {
      const audioFile = new File(
        [recordedAudio.audioBlob],
        `${recordedAudio.audioBlob && recordedAudio.audioBlob.size}.mp3`,
        {
          type: "audio/mp3;"
        }
      );
      let data = {
        url: {
          audio: recordedAudio.audioBlob ? audioFile : "",
          images: [...addedFiles]
        },
        pid: props.profile.currentProfile,
        timestamp: +new Date(`${formData.date}T${formData.time}`),
        type: "symptom",
        location: formData.location,
        severityScale: parseInt(formData.severityScale),
        description: formData.description?.trim()
      };
      props.addSymptom(data, props.navigate);
    } else if (addSymptom === false) {
      const symptomDocument = props.documents.data[
        props.profile.currentProfile
      ].find((symptom) => symptom.documentId === documentId);
      const updatedAudio = new File(
        [recordedAudio.audioBlob],
        `${formData.documentId}.mp3`,
        {
          type: "audio/mp3;"
        }
      );
      let data = {
        pid: props.profile.currentProfile,
        timestamp: +new Date(`${formData.date}T${formData.time}`),
        type: "symptom",
        location: formData.location,
        severityScale: parseInt(formData.severityScale),
        url: {
          audio: recordedAudio.audioBlob ? updatedAudio : formData.audio,
          images: [
            ...(isValidObject(symptomDocument.url) &&
            isValidArray(symptomDocument.url.images)
              ? symptomDocument.url.images
              : [])
          ]
        },
        description: formData.description?.trim(),
        documentId: formData.documentId
      };
      props.editSymptom(formData.documentId, data, props.navigate);
    }
  };

  //symptom canvas viewport adjustment function
  useEffect(() => {
    if (addPicturesElementRef.current) {
      addPicturesSectionWidthRef.current =
        addPicturesElementRef.current.offsetWidth;
    }
    const rerenderComponentToResize = (event) => {
      addPicturesSectionWidthRef.current = event.currentTarget.innerWidth;
      setRerender(
        `Height: ${event.currentTarget.innerHeight} - Width: ${event.currentTarget.innerWidth}`
      );
    };
    window.addEventListener("resize", rerenderComponentToResize);
    return () => {
      window.removeEventListener("resize", rerenderComponentToResize);
    };
  }, []);

  useEffect(() => {
    if (fileToBeCropped) {
      setCropperReady(false);
      var canvas = document.getElementById("imageData");
      var ctx = canvas.getContext("2d");
      var maxW = 1024;
      var maxH = 1024;
      var img = new Image();
      img.onload = function () {
        var iw = img.width;
        var ih = img.height;
        var scale = Math.min(maxW / iw, maxH / ih);
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        cropper.current = new Cropper(canvas, {
          viewMode: 2,
          maxContainerWidth: 500,
          maxCanvasWidth: 100,
          maxCropBoxWidth: 20,
          background: false,
          borderRadius: 50,
          zoomable: true,
          dragMode: "move",
          responsive: true,
          guides: false,
          rotatable: true
        });
        setCropperReady(true);
      };
      img.src = fileToBeCropped;
    }
  }, [fileToBeCropped]);

  function cropHandler() {
    cropper.current.getCroppedCanvas().toBlob(
      (blob) => {
        //for blob to image
        const file = new File(
          [blob],
          `${addedFiles.length}-${+new Date()}.png`,
          {
            type: "image/png"
          }
        );
        setAddedFiles([...addedFiles, file]);
      },
      "image/png",
      1
    );
    setFileToBeCropped("");
  }

  function fileHandler(event) {
    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png")
    ) {
      setFileToBeCropped(URL.createObjectURL(event.target.files[0]));
    } else {
      setErrorStatus({
        code: "custom",
        message: "Kindly upload a valid File"
      });
    }
  }

  function handleClose() {
    setFileToBeCropped("");
  }

  const showSymptomScreen = () => {
    if (fileToBeCropped === "" && selectedFileToViewIndex === null) return true;
    return false;
  };

  const showCropperComp = () => {
    if (
      fileToBeCropped &&
      fileToBeCropped !== "" &&
      selectedFileToViewIndex === null
    )
      return true;
    return false;
  };

  const showImageFullViewComp = () => {
    if (
      fileToBeCropped === "" &&
      selectedFileToViewIndex !== null &&
      isValidArray(addedFiles) &&
      addedFiles[selectedFileToViewIndex]
    )
      return true;
    return false;
  };

  const imgSrc = (file) => {
    if (typeof file === "string") {
      return file;
    } else if (file instanceof File) {
      return window.URL.createObjectURL(file);
    }
  };

  return (
    <div data-cy="symptom-screen" className="inherit-parent-height">
      {showSymptomScreen() === true ? (
        <div className="inherit-parent-height">
          <Header
            title={
              show === "locateSymptom" ? "Locate symptom" : "Describe symptom"
            }
            supportIconOnClick={() => {
              props.navigate("/query");
            }}
            backButtonOnClick={() => {
              props.navigate(-1);
            }}
          />
          <div className=" overflow-y-auto inherit-parent-height remaining-body-height">
            <div
              className={`padding-top-large max-width-588px margin-horizontal-auto inherit-parent-width inherit-parent-height padding-right-large padding-left-large ${
                show === "locateSymptom" && `overflow-hidden`
              }`}
            >
              <div className="flex-center-children-horizontally">
                <div className="flex-justify-content-space-between font-size-small border-radius-default box-shadow-default padding-small background-white">
                  <span
                    className={`cursor-pointer font-family-gilroy-medium border-radius-default padding-top-small padding-bottom-small padding-right-large padding-left-large ${
                      show === "locateSymptom"
                        ? "font-color-secondary background-color-grey"
                        : "font-color-tertiary"
                    }`}
                    onClick={() => {
                      setShow("locateSymptom");
                    }}
                  >
                    Location
                  </span>
                  <span
                    className={`cursor-pointer font-family-gilroy-medium border-radius-default padding-top-small padding-bottom-small padding-right-medium padding-left-medium ${
                      show === "describe"
                        ? "font-color-secondary background-color-grey"
                        : "font-color-tertiary"
                    }`}
                    onClick={() => {
                      setShow("describe");
                    }}
                  >
                    Description
                  </span>
                </div>
              </div>
              {show === "locateSymptom" ? (
                <div className="inherit-parent-height">
                  <SymptomsRenderer
                    gender={
                      props.profile.currentProfile &&
                      isProfilesLoaded(props.profile.data) &&
                      !!props.profile.data?.[props.profile.currentProfile]
                        ?.private?.gender
                        ? props.profile.data[
                            props.profile.currentProfile
                          ].private.gender.toLowerCase()
                        : "male"
                    }
                    onExport={(locations) => {
                      setFormData({ ...formData, location: locations });
                    }}
                    locations={formData.location}
                  />
                </div>
              ) : (
                <form
                  onChange={(event) => {
                    formOnChangeHandler(event);
                  }}
                  onSubmit={(event) => symptomSubmitHandler(event)}
                >
                  <InputBox
                    data-cy="date"
                    className="inherit-parent-width border-radius-default margin-top-large"
                    type="date"
                    name="date"
                    value={formData.date}
                  />
                  <InputBox
                    data-cy="time"
                    className="inherit-parent-width border-radius-default margin-top-large"
                    type="time"
                    name="time"
                    value={formData.time}
                    isValidInput={(isValid) => {
                      setIsValidInput(isValid);
                      // isValidInput.current = isValid;
                    }}
                    validation={(value) => timeValidation(value, formData.date)}
                  />
                  <TextArea
                    labelClassName="letter-spacing-4-percentage"
                    data-cy="description"
                    name="description"
                    label="Describe your symptom"
                    className="margin-top-large"
                    value={formData.description}
                  />
                  <InputSlider
                    label="SEVERITY SCALE"
                    name="severityScale"
                    min={"1"}
                    value={formData.severityScale}
                    max={"10"}
                    onChange={() => {}}
                    className="margin-top-large"
                  />

                  <div className="flex-justify-content-space-between margin-vertical-large flex-align-items-center">
                    {((addSymptom === false && formData.audio.length > 0) ||
                      addSymptom === true) && (
                      <AudioPlayer
                        className={`${
                          addSymptom && "flex-basis-80-percentage"
                        }`}
                        src={
                          updateAudio === true
                            ? recordedAudio.audioURL
                            : formData.audio.length > 0 &&
                              isValidObject(props.downloads.data.symptoms) &&
                              isValidObject(
                                props.downloads.data.symptoms[documentId]
                              ) &&
                              props.downloads.data.symptoms[documentId]
                                .downloadUrl
                            ? props.downloads.data.symptoms[documentId]
                                .downloadUrl
                            : typeof recordedAudio.audioURL === "string"
                            ? recordedAudio.audioURL
                            : ""
                        }
                      />
                    )}
                    {/* )} */}
                    {addSymptom === true && (
                      <div
                        className="voice-recorder-contol-button-size flex-place-children-page-center background-white box-shadow-default border-radius-50-percentage"
                        onClick={() => voiceRecorderHandler()}
                        data-cy="audio-record-control-button"
                      >
                        <img
                          src={
                            voiceRecorderControl === "start"
                              ? stopIcon
                              : voiceRecorderControl === "stop"
                              ? closeIcon
                              : startIcon
                          }
                          alt="voice-record-control-icon"
                          width="100%"
                          className="control-icon-width"
                        />
                      </div>
                    )}
                  </div>

                  <section
                    ref={addPicturesElementRef}
                    className="inherit-parent-width"
                  >
                    {
                      <>
                        {props.downloads.loading === true &&
                        (addedFiles.length > 0 ||
                          props.documents.currentDocument?.url?.images?.length >
                            0) ? (
                          <SymptomImageSuspense
                            width={`${
                              addPicturesSectionWidthRef.current
                                ? addPicturesSectionWidthRef.current / 3
                                : 80
                            }px`}
                            height={`${
                              addPicturesSectionWidthRef.current
                                ? addPicturesSectionWidthRef.current / 3
                                : 80
                            }px`}
                          />
                        ) : (
                          <>
                            {addSymptom === false &&
                              formData?.url?.images?.length > 0 && (
                                <h6 className="font-weight-normal font-family-gilroy-regular font-color-secondary font-size-small margin-bottom-default">
                                  PICTURES
                                </h6>
                              )}
                            {addSymptom === true && (
                              <h6 className="font-weight-normal font-family-gilroy-regular font-color-secondary font-size-small margin-bottom-default">
                                PICTURES
                              </h6>
                            )}
                            <ul className="flex-direction-row list-style-type-none column-gap-3-percentage">
                              {addedFiles.map((file, index) => (
                                <li
                                  className="border-radius-default"
                                  key={`symptom-image-key-${index}`}
                                  style={{
                                    width: `${
                                      addPicturesSectionWidthRef.current
                                        ? addPicturesSectionWidthRef.current / 3
                                        : 80
                                    }px`,
                                    height: `${
                                      addPicturesSectionWidthRef.current
                                        ? addPicturesSectionWidthRef.current / 3
                                        : 80
                                    }px`
                                  }}
                                >
                                  <img
                                    src={imgSrc(file)}
                                    alt="symptom"
                                    data-cy={`symptom-image-${index}`}
                                    className="inherit-parent-height border-radius-default inherit-parent-width object-fit-cover border-solid-1px-grey"
                                    onClick={() => {
                                      setSelectedFileToViewIndex(index);
                                    }}
                                  />
                                </li>
                              ))}
                              {addedFiles.length < 3 && addSymptom === true && (
                                <li
                                  className="border-radius-default background-color-grey flex-place-children-page-center"
                                  onClick={() =>
                                    symptomImageInputFieldRef.current.click()
                                  }
                                  style={{
                                    width: `${
                                      addPicturesSectionWidthRef.current
                                        ? addPicturesSectionWidthRef.current / 3
                                        : 80
                                    }px`,
                                    height: `${
                                      addPicturesSectionWidthRef.current
                                        ? addPicturesSectionWidthRef.current / 3
                                        : 80
                                    }px`
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V5.66667C7 6.40305 6.40305 7 5.66667 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H5.66667C6.40305 9 7 9.59695 7 10.3333V15C7 15.5523 7.44772 16 8 16C8.55229 16 9 15.5523 9 15V10.3333C9 9.59695 9.59695 9 10.3333 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H10.3333C9.59695 7 9 6.40305 9 5.66667V1Z"
                                      fill="#4E4E4E"
                                    />
                                  </svg>
                                  <input
                                    ref={symptomImageInputFieldRef}
                                    type="file"
                                    accept="image/jpeg,image/png"
                                    name="symptomImageUploaderInput"
                                    label="inputFile"
                                    onClick={(event) => {
                                      event.target.value = "";
                                    }}
                                    className="display-none"
                                    onChange={(event) => {
                                      fileHandler(event);
                                    }}
                                  />
                                </li>
                              )}
                            </ul>
                          </>
                        )}
                      </>
                    }
                  </section>
                  <Button
                    type="submit"
                    boxShadow={false}
                    loading={props.documents.loading}
                    disabled={
                      (typeof formData.description === "string" &&
                        formData.description.trim().length === 0 &&
                        !recordedAudio.audioBlob &&
                        !formData.audio) ||
                      voiceRecorderControl === "start" ||
                      isValidInput === false
                        ? true
                        : false
                    }
                    text={
                      addSymptom === true ? "Add symptom" : "Update symptom"
                    }
                    className="margin-vertical-large"
                    data-cy="add-symptom-button"
                  />
                </form>
              )}
            </div>
          </div>
        </div>
      ) : showCropperComp() === true ? (
        <>
          <div
            data-cy="cropper-modal"
            className="inherit-parent-height inherit-parent-width flex-center-children-horizontally flex-direction-column background-color-black"
          >
            <div className="inherit-parent-height inherit-parent-width max-height-90-percentage padding-top-default">
              <canvas
                className="display-block max-width-100-percentage"
                id="imageData"
                data-cy="cropper-canvas"
              ></canvas>
            </div>
            <footer className="padding-larger inherit-parent-width flex-align-items-center flex-justify-content-space-between">
              <button
                data-cy="crop-img-cancel-button"
                type="submit"
                variant="secondary"
                className="background-transparent font-color-white font-family-gilroy-medium font-size-medium"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </button>
              <button
                data-cy="crop-img-rotate-button"
                type="button"
                className="background-transparent font-color-white  font-family-gilroy-medium font-size-medium"
                variant="secondary"
                onClick={() => {
                  cropper.current.rotate(90);
                  cropper.current.zoomTo(0);
                }}
              >
                Rotate
              </button>
              <button
                data-cy="crop-img-save-button"
                type="button"
                className="background-transparent font-color-white font-family-gilroy-medium font-size-medium"
                disabled={!cropperReady}
                onClick={(event) => {
                  if (
                    cropper.current.getData(true).width <= 50 ||
                    cropper.current.getData(true).height <= 50
                  ) {
                    return;
                  }
                  cropHandler(event);
                }}
              >
                Save
              </button>
            </footer>
          </div>
        </>
      ) : (
        showImageFullViewComp() === true && (
          <>
            <div
              data-cy="image-full-view-comp"
              className="inherit-parent-height inherit-parent-width flex-center-children-horizontally flex-direction-column background-color-black"
            >
              <div className="flex-center-children-horizontally flex-direction-column flex-grow-1 max-height-90-percentage">
                <img
                  src={imgSrc(addedFiles[selectedFileToViewIndex])}
                  alt="symptom"
                  className="inherit-parent-height max-height-90-percentage object-fit-contain"
                />
              </div>
              <footer className="padding-larger inherit-parent-width flex-align-items-center flex-justify-content-space-between">
                <button
                  data-cy="image-full-view-comp-cancel-btn"
                  type="submit"
                  variant="secondary"
                  className="background-transparent font-color-white font-family-gilroy-medium font-size-medium"
                  onClick={() => {
                    // handleClose();
                    setSelectedFileToViewIndex(null);
                  }}
                >
                  Cancel
                </button>
                {addSymptom === true && (
                  <button
                    data-cy="image-full-view-comp-delete-btn"
                    type="button"
                    className="background-transparent font-color-white font-family-gilroy-medium font-size-medium"
                    onClick={() => {
                      let addedFilesTemp = [...addedFiles];
                      addedFilesTemp.splice(selectedFileToViewIndex, 1);
                      setSelectedFileToViewIndex(null);
                      setAddedFiles([...addedFilesTemp]);
                    }}
                  >
                    Delete
                  </button>
                )}
              </footer>
            </div>
          </>
        )
      )}
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    documents: state.documents,
    downloads: state.downloads
  };
};

const mapDispatchToProps = function () {
  return {
    addSymptom: (data, navigate) => addSymptom(data, navigate),
    editSymptom: (documentId, data, navigate) =>
      updateSymptom(documentId, data, navigate),
    setCurrentDocument: (documentDocId) => setCurrentDocument(documentDocId),
    removeCurrentDocument: () => removeCurrentDocument(),
    getDocumentUrl: (documentId) => getDocumentDownloadUrl(documentId)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Symptom);

function SymptomImageSuspense(props) {
  return (
    <article
      data-cy="symptom-images-suspense"
      className="screen-loader-wrapper inherit-parent-width"
    >
      <div className="shimmer border-radius-default padding-default width-40-percentage margin-bottom-medium" />
      <ul className="inherit-parent-width flex-direction-row list-style-type-none column-gap-3-percentage">
        <li
          className="shimmer border-radius-default"
          style={{
            width: props.width,
            height: props.height
          }}
        />
        <li
          className="shimmer border-radius-default"
          style={{
            width: props.width,
            height: props.height
          }}
        />
        <li
          className="shimmer border-radius-default"
          style={{
            width: props.width,
            height: props.height
          }}
        />
      </ul>
    </article>
  );
}
