import { all, takeEvery } from "@redux-saga/core/effects";
import { app } from "../../utils/constants";
import { setErrorStatus } from "../status/actions";
import { setOAuthAccessToken } from "../authentication/actions";

export const fitnessActionTypes = {
  INITIALIZE_GOOGLE_API: "INITIALIZE_GOOGLE_API",
  SIGN_IN_WITH_GOOGLE_FOR_GOOGLE_API: "SIGN_IN_WITH_GOOGLE_FOR_GOOGLE_API",
  GET_GOOGLEfITNESS_DATA: "GET_GOOGLEfITNESS_DATA"
};

// http://localhost/fitnessTracker?code=4/0AX4XfWg4zjqw8W8RM5hc6EthgqD3r3Gtk6cd8vL63DqDktWXK_X24TzYmOnAthRFkh1Diw&scope=email%20profile%20https://www.googleapis.com/auth/fitness.activity.read%20openid%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&authuser=2&hd=byepo.in&prompt=consent

function initializeGoogleApiWorker(action) {
  try {
    const client = window.google.accounts.oauth2.initCodeClient({
      client_id:
        "599829245090-f0rj1b6rudjmevj3hsbcnato1ielis71.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/fitness.activity.read",
      ux_mode: "redirect",
      redirect_uri: `${app.baseUrl}/fitnesstrackers`,
      callback: (response) => {
        setOAuthAccessToken(response.accessToken, "abc@gmail.com");
      }
    });
    client.requestCode();
  } catch (error) {
    setErrorStatus(error);
  }
}
export default function* fitnessWatcher() {
  yield all([takeEvery("INITIALIZE_GOOGLE_API", initializeGoogleApiWorker)]);
}

// ("You have created a new client application that uses libraries for user authentication or authorization that will soon be deprecated. New clients must use the new libraries instead; existing clients must also migrate before these libraries are deprecated. See the [Migration Guide](https://developers.google.com/identity/gsi/web/guides/gis-migration) for more information.");
