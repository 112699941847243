import React, { useEffect } from "react";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import nextIcon from "../../assets/icons/Next.svg";
import googleFit from "../../assets/images/googleFit.png";
import { sendOAuthAccessCode } from "../../services/api";
import { initializeGoogleApi } from "../../redux/fitness/actions";
const FitnessTrackers = (props) => {
  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    if (
      code &&
      props.patients.profiles?.[props.patients.currentProfile] &&
      props.auth.data.accessToken
    ) {
      /**
       * send OAuth access token from redirect URL to fetch the google fit data
       */
      props.sendOAuthAccessCode(code);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className=" inherit-parent-height overflow-hidden ">
      <Header
        title="Fitness tracking"
        backButtonOnClick={() => {
          props.navigate(-1);
        }}
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
      />
      <section className=" remaining-body-height overflow-y-auto max-width-588px margin-horizontal-auto ">
        {props.profile.data?.[
          props.profile.currentProfile
        ]?.fitness?.hasOwnProperty("googleFit") && (
          <React.Fragment>
            <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary text-transform-uppercase padding-top-larger padding-bottom-medium">
              Connected apps
            </div>
            {Object.keys(
              props.profile.data[props.profile.currentProfile].fitness
            ).map((fit) => (
              <React.Fragment key={fit}>
                <FitnessTrackerAppCard
                  onClick={() => {}}
                  name={fit}
                  icon={googleFit}
                  isConnected={true}
                />
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
        {!props.profile.data?.[
          props.profile.currentProfile
        ]?.fitness?.hasOwnProperty("googleFit") && (
          <>
            <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary text-transform-uppercase padding-top-larger padding-bottom-medium">
              Connect a tracker app
            </div>
            <FitnessTrackerAppCard
              name="Google fit"
              icon={googleFit}
              onClick={() => {
                props.initGoogleApi();
              }}
            />
          </>
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth
});

const mapDispatchToProps = function () {
  return {
    initGoogleApi: () => initializeGoogleApi(),
    sendOAuthAccessCode: (accessCode) => sendOAuthAccessCode(accessCode)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FitnessTrackers);

const FitnessTrackerAppCard = ({ icon, name, isConnected, onClick }) => {
  return (
    <div className="display-flex padding-horizontal-default " data-cy={name}>
      <div
        className="display-flex flex-grow-1 flex-align-items-center padding-default cursor-pointer"
        onClick={() => {
          onClick();
        }}
      >
        <RoundedProfilePicture
          className="margin-top-default"
          size={"medium"}
          src={icon}
        />
        <div className="flex-grow-1 flex-direction-column display-flex padding-left-medium">
          <span className="font-size-medium font-family-gilroy-regular font-color-secondary text-transform-capitalize">
            {name}
          </span>
          {isConnected && (
            <span className=" font-size-smaller letter-spacing-4-percentage text-transform-uppercase font-color-secondary ">
              connected
            </span>
          )}
        </div>
        {!isConnected && (
          <img
            className="clinic-connection-remove-icon-height cursor-pointer  "
            src={nextIcon}
            width="auto"
            alt=""
          />
        )}
      </div>
    </div>
  );
};
