import React, { useEffect, useRef, useCallback } from "react";
import deleteIcon from "../../assets/delete.svg";
import Sortable from "sortablejs";
import Button from "../Button/Button";
import { isValidArray } from "../../services/validators";
import "./ReArrangeAndAddPrescription.css";
import "../../design-system.css";
import PropTypes from "prop-types";

function ReArrangeAndAddPrescription(props) {
  const sortableRef = useRef(null);
  const sortedArrayRef = useRef(null);
  const addImageCardElementRef = useRef(null);
  const sortableCallBackRef = useRef(null);

  const createSortable = useCallback(() => {
    sortableCallBackRef.current = Sortable.create(sortableRef.current, {
      draggable: ".allow-dragging",
      dataIdAttr: "data-sort",
      ghostClass: ".sortable-ghost",
      onSort: function (/**Event*/ evt) {
        [...sortableRef.current.getElementsByTagName("li")].forEach(
          (li, index) => {
            if (li.getElementsByTagName("h6")[0]) {
              li.getElementsByTagName("h6")[0].innerText = index + 1;
            }
          }
        );
        sortedArrayRef.current = sortableCallBackRef.current.toArray();
      },
      onMove: function (event) {
        if (event.related.tagName === "UL") {
          return false;
        } else {
          return true;
        }
      },
      onEnd: (_) => {
        const children = [...sortableRef.current.childNodes];
        const addImageCardElementIndex = Array.prototype.indexOf.call(
          children,
          addImageCardElementRef.current
        );

        if (
          sortableRef.current.childNodes.length - 1 !==
            addImageCardElementIndex &&
          addImageCardElementIndex !== -1
        ) {
          let newSrc = [...children];
          newSrc.splice(addImageCardElementIndex, 1);
          newSrc.push(addImageCardElementRef.current);
          newSrc.forEach((it) => sortableRef.current.appendChild(it));
          [...sortableRef.current.getElementsByTagName("li")].forEach(
            (li, index) => {
              if (li.getElementsByTagName("h6")[0]) {
                li.getElementsByTagName("h6")[0].innerText = index + 1;
              }
            }
          );
          sortedArrayRef.current = sortableCallBackRef.current.toArray();
        }
      }
    });
    // sortableCallBackRef.current = sortable;
  }, []);

  // for creating sortable
  useEffect(() => {
    if (sortableCallBackRef.current && props.isLoading === true) {
      sortableCallBackRef.current.destroy();
    } else if (sortableCallBackRef.current && props.isLoading === false) {
      createSortable();
    }
  }, [props.isLoading, createSortable]);

  function onPageDelete(index, deletedPageNumber) {
    let newSrc = props.src;
    newSrc.splice(index, 1);
    typeof props.setSrc === "function" && props.setSrc([...newSrc]);
    if (isValidArray(sortedArrayRef.current)) {
      let newArray = sortedArrayRef.current
        .filter((pageNumber) => pageNumber !== deletedPageNumber)
        .map((pageNumber) => {
          if (pageNumber > deletedPageNumber) {
            return String(pageNumber - 1);
          } else {
            return pageNumber;
          }
        });
      sortedArrayRef.current = newArray;
    }
  }

  // init sortable
  useEffect(() => {
    createSortable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // for creating new sortable image
  useEffect(() => {
    if (isValidArray(props.src)) {
      [...sortableRef?.current?.getElementsByTagName("li")]?.forEach(
        (li, index) => {
          if (li.getElementsByTagName("h6")[0]) {
            li.getElementsByTagName("h6")[0].innerText = index + 1;
          }
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src]);

  return (
    <div
      className="inherit-parent-height display-flex-direction-column "
      data-cy="reArrange-and-add-prescription-comp"
    >
      <h5 className="font-weight-normal height-5-percentage font-family-gilroy-regular font-size-small font-color-secondary inherit-parent-width text-align-center padding-top-default ">
        ADD PAGES (MAX. 4)
      </h5>
      <div className="height-80-percentage padding-larger flex-grow-1">
        {isValidArray(props.src) && props.src && (
          <ul
            ref={sortableRef}
            className="inherit-parent-height flex-wrap-wrap list-style-type-none gap-3-percentage flex-justify-content-space-between"
          >
            {isValidArray(props.src) &&
              props.src.map((src, index) => {
                return (
                  <SelectedImageCard
                    key={index}
                    index={index}
                    pageNumber={
                      sortedArrayRef.current
                        ? sortedArrayRef.current[index]
                        : index
                    }
                    src={src}
                    onPageDelete={onPageDelete}
                    sortedArray={sortedArrayRef.current}
                  />
                );
              })}
            {props.src.length < 4 && (
              <li
                className="item height-width-46-percentage"
                ref={addImageCardElementRef}
                data-cy="add-image-card-element"
              >
                <section
                  className={`inherit-parent-height`}
                  onClick={() => {
                    typeof props.onAddExtraImageClick === "function" &&
                      props.onAddExtraImageClick();
                  }}
                >
                  <div className="inherit-parent-height position-relative height-90-percentage">
                    <div
                      className={`  inherit-parent-height inherit-parent-width border-radius-default background-color-grey flex-place-children-page-center`}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1V5.66667C7 6.40305 6.40305 7 5.66667 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H5.66667C6.40305 9 7 9.59695 7 10.3333V15C7 15.5523 7.44772 16 8 16C8.55229 16 9 15.5523 9 15V10.3333C9 9.59695 9.59695 9 10.3333 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H10.3333C9.59695 7 9 6.40305 9 5.66667V1Z"
                          fill="#4E4E4E"
                        />
                      </svg>
                    </div>
                  </div>
                </section>
              </li>
            )}
          </ul>
        )}
      </div>
      <footer className="padding-larger ">
        <h6 className="font-family-gilroy-regular font-size-small font-color-secondary inherit-parent-width text-align-center ">
          Drag and drop to rearrange the pages
        </h6>
        <Button
          className="margin-top-medium"
          text={
            props.uploadButtonText ? props.uploadButtonText : "Add prescription"
          }
          loading={props.isLoading}
          onClick={() => {
            if (!isValidArray(props.src)) return;
            if (isValidArray(sortedArrayRef.current)) {
              let sortedFiles = [];
              sortedArrayRef.current.forEach((itemIndex, index) => {
                const fileBlob = props.src[itemIndex].slice(
                  0,
                  props.src[itemIndex].size,
                  "image/png"
                );
                const newlyNamedFile = new File(
                  [fileBlob],
                  `${props.fileName}/${index + 1}/${props.src.length}.png`,
                  { type: "image/png" }
                );
                sortedFiles = [...sortedFiles, newlyNamedFile];
              });
              if (typeof props.onAddPrescriptionClick === "function") {
                props.onAddPrescriptionClick(sortedFiles);
              }
            } else {
              let sortedFiles = [];
              props.src.forEach((_, index) => {
                const fileBlob = props.src[index].slice(
                  0,
                  props.src[index].size,
                  "image/png"
                );
                const newlyNamedFile = new File(
                  [fileBlob],
                  `${props.fileName}/${index + 1}/${props.src.length}.png`,
                  { type: "image/png" }
                );
                sortedFiles = [...sortedFiles, newlyNamedFile];
              });
              if (typeof props.onAddPrescriptionClick === "function") {
                props.onAddPrescriptionClick(sortedFiles);
              }
            }
            // signUpHandler(event);
          }}
          data-cy="arrange-add-prescription-btn"
          boxShadow={false}
        />
      </footer>
    </div>
  );
}

function SelectedImageCard(props) {
  const imgRef = useRef(null);

  const imgSrc = () => {
    if (typeof props.src === "string") {
      return props.src;
    } else if (props.src instanceof File) {
      return window.URL.createObjectURL(props.src);
    }
  };

  return (
    <li
      data-sort={props.index}
      className="allow-dragging sortable-ghost height-width-46-percentage"
    >
      <section className="inherit-parent-height">
        <div className="inherit-parent-height position-relative height-90-percentage">
          <img
            ref={imgRef}
            alt=""
            className="inherit-parent-height border-radius-default inherit-parent-width object-fit-fill border-solid-1px-grey"
            src={imgSrc()}
          />
          <div
            className="height-width-30px background-color-transparent-black position-absolute bottom-10px right-10px  border-radius-100-percentage flex-place-children-page-center "
            onClick={() =>
              typeof props.onPageDelete === "function" &&
              props.onPageDelete(props.index, props.pageNumber)
            }
          >
            <img src={deleteIcon} alt="delete" />
          </div>
        </div>
        <h6 className="font-family-gilroy-regular font-size-small font-color-secondary inherit-parent-width text-align-center padding-top-small">
          {props.index + 1}
        </h6>
      </section>
    </li>
  );
}

ReArrangeAndAddPrescription.propTypes = {
  /**
   * source of the image
   */
  src: PropTypes.array.isRequired,
  /**
   * callback function return the src array. max length 4
   */
  setSrc: PropTypes.func,
  /**
   * onAddExtraImageClick
   */
  onAddExtraImageClick: PropTypes.func,
  /**
   * upload button content. default content is `Add prescription`
   */
  uploadButtonText: PropTypes.string,
  /**
   * file name
   */
  fileName: PropTypes.string.isRequired,
  /**
   * callback function which returns the sorted files as `arg`
   */
  onAddPrescriptionClick: PropTypes.func,
  /**
   * index number of file
   */
  index: PropTypes.number,
  /**
   * callback function
   */
  onPageDelete: PropTypes.func
};

ReArrangeAndAddPrescription.defaultProps = {
  src: [],
  setSrc: undefined,
  onAddExtraImageClick: undefined,
  uploadButtonText: "Add prescription",
  fileName: "default",
  onAddPrescriptionClick: undefined,
  index: 0,
  onPageDelete: undefined
};

export default ReArrangeAndAddPrescription;
