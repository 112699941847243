import store from "../store/store";
import { notificationActionTypes } from "./saga";

/**
 * @module NotificationSaga
 */

/**
 * set notification info
 * @param {string} notifications
 */
export function setNotifications(notifications, lastVisible, profileId) {
  store.dispatch({
    type: notificationActionTypes.SET_NOTIFICATION,
    payload: {
      data: notifications,
      lastVisible: lastVisible,
      profileId: profileId
    }
  });
}

/**
 * set notification read
 * @param {object} notifications
 */
export function setNotificationRead(notifications, type) {
  store.dispatch({
    type: notificationActionTypes.SET_NOTIFICATION_READ,
    payload: {
      notifications: notifications,
      type: type
    }
  });
}

/**
 * get next set of notification
 * @param {number} lastDocPageNumber
 */
export function updateNotifications(lastDocPageNumber) {
  store.dispatch({
    type: notificationActionTypes.UPDATE_NOTIFICATION,
    payload: {
      lastDocPageNumber: lastDocPageNumber
    }
  });
}
