import React, { useEffect, useRef } from "react";
import "./InputSlider.css";
import PropTypes from "prop-types";

InputSlider.propTypes = {
  /**
   * disabled state
   */
  disabled: PropTypes.bool,
  /**
   * value of the input element
   */
  value: PropTypes.string,
  /**
   * class name for the parent element
   */
  className: PropTypes.string,
  /**
   * label content for the component
   */
  label: PropTypes.string,
  /**
   * selector attribute for cypress testing
   */
  "data-cy": PropTypes.string,
  /**
   * name attribute for input element
   */
  name: PropTypes.string,
  /**
   * min value for slider
   */
  min: PropTypes.string,
  /**
   * max value for the slider
   */
  max: PropTypes.string,
  /**
   * onChange event of input element
   */
  onChange: PropTypes.func
};

InputSlider.defaultProps = {
  disabled: false,
  value: "1",
  className: "",
  label: null,
  "data-cy": "input-slider",
  name: null,
  min: "1",
  max: "10",
  onChange: undefined
};

export function InputSlider(props) {
  const severityScaleRef = useRef();
  const severityScalePinRef = useRef();

  //slider position
  useEffect(() => {
    if (severityScalePinRef.current && severityScaleRef.current) {
      severityScalePinRef.current.style.left = `${
        (props.value / 10) * severityScaleRef.current.offsetWidth ===
        severityScaleRef.current.offsetWidth
          ? severityScaleRef.current.offsetWidth - 15
          : (props.value / 10) * severityScaleRef.current.offsetWidth
      }px`;
    }
  }, [props.value]);

  return (
    <div
      className={`padding-default border-radius-default ${props?.className}`}
    >
      <div className="font-family-gilroy-medium font-color-secondary font-size-small">
        {props.label}
      </div>
      <div className="position-relative inherit-parent-width padding-top-small padding-bottom-small">
        <input
          disabled={props.disabled}
          data-cy={props["data-cy"] ? props["data-cy"] : "input-slider"}
          ref={severityScaleRef}
          name={props.name}
          type="range"
          min={props.min}
          max={props.max}
          onChange={props.onChange}
          value={props.value}
          className="input-slider-line-height inherit-parent-width input-slider-line-background slider-thumb cursor-pointer"
        />
        <span
          ref={severityScalePinRef}
          className="position-absolute font-family-gilroy-medium text-align-center severity-range-thumb-size background-white box-shadow-default flex-place-children-page-center font-size-small border-radius-50-percentage position-absolute-top-for-input-range-thumb padding-smaller"
          style={
            severityScaleRef.current && {
              left: `${
                (props.value / 10) * severityScaleRef.current.offsetWidth ===
                severityScaleRef.current.offsetWidth
                  ? severityScaleRef.current.offsetWidth - 15
                  : (props.value / 10) * severityScaleRef.current.offsetWidth
              }px`
            }
          }
        >
          {props.value}
        </span>
      </div>
    </div>
  );
}
