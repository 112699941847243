import React, { useRef, useEffect } from "react";
import MaleFront from "../../assets/male_front.png";
import MaleBack from "../../assets/male_back.png";
import FemaleFront from "../../assets/female_front.png";
import FemaleBack from "../../assets/female_back.png";
import pointIcon from "../../assets/Location_mark_dot.png";
import RotateIcon from "../../assets/rotate.svg";
import ClearIcon from "../../assets/clear.svg";
import { isValidArray, isValidObject } from "../../services/validators";
import "./SymptomRenderer.css";
import "../../design-system.css";
import PropTypes from "prop-types";

const testPointers = {
  front: [
    {
      posX: 42.112518638692876,
      posY: 5.080772387849588
    },
    {
      posX: 52.506783871667785,
      posY: 6.34459545262052
    },
    {
      posX: 44.62147921216958,
      posY: 12.821688659571548
    }
  ],
  back: [
    {
      posX: 38.52828924801187,
      posY: 64.79641219827613
    },
    {
      posX: 34.227213979194666,
      posY: 75.22295248263632
    },
    {
      posX: 37.45302043080757,
      posY: 84.06971393603284
    },
    {
      posX: 56.80785914048499,
      posY: 69.6937265742635
    },
    {
      posX: 54.29889856700829,
      posY: 54.52784979701231
    }
  ]
};
function SymptomsRenderer({
  gender = "male",
  locations = { ...testPointers },
  readOnly = false,
  onExport = (val) => {}
}) {
  const symptomsRendererRef = useRef(null);
  const canvasRef = useRef(null);
  const context = useRef(null);
  const avatarSide = useRef("FRONT");
  const frontPointersLocation = useRef([]);
  const backPointersLocation = useRef([]);
  const avatarImage = new Image();
  const pointerImg = new Image();
  const pointerImageHeight = 8;
  const pointerImageWidth = 8;

  // set mouse clicked listener
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas !== null && canvas !== undefined && canvas.parentNode) {
      context.current = canvas.getContext("2d");
      if (isValidObject(locations)) {
        if (isValidArray(locations.front)) {
          frontPointersLocation.current = locations.front;
        }
        if (isValidArray(locations.back)) {
          backPointersLocation.current = locations.back;
        }
      }
      draw();
      //set Listener when readOnly is FALSE
      if (!readOnly) {
        canvas.addEventListener("mouseup", mouseClicked, false);
      }
    }
    return () => {
      if (!readOnly) {
        canvas.removeEventListener("mouseup", mouseClicked, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef.current]);

  const calculateAspectRatioFit = (
    srcHeight,
    srcWidth,
    maxHeight,
    maxWidth
  ) => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  };

  const valueToPercentage = (posX, posY, height, width) => {
    const result = {
      posX: +((100 * posX) / width).toFixed(2),
      posY: +((100 * posY) / height).toFixed(2)
    };
    return result;
  };

  const percentageToValue = (posXpercentage, posYpercentage, height, width) => {
    const result = {
      posX: width * (posXpercentage / 100),
      posY: height * (posYpercentage / 100)
    };
    return result;
  };

  const mouseClicked = (mouse) => {
    if (avatarSide.current === "FRONT") {
      if (frontPointersLocation.current.length > 4) return;
    } else {
      if (backPointersLocation.current.length > 4) return;
    }

    // Get current mouse coords
    var rect = canvasRef.current.getBoundingClientRect();
    var mousePosX = mouse.x - rect.left;
    var mousePosY = mouse.y - rect.top;

    const accurateMousePosX = mousePosX - pointerImageWidth / 2;
    const accurateMousePosY = mousePosY - pointerImageHeight / 2;

    const rgba_ALPHA = context.current.getImageData(mousePosX, mousePosY, 1, 1)
      .data[3]; // [0]R [1]G [2]B [3]A

    if (rgba_ALPHA === 0) return null; // clicked on Transparent Area

    const positionPercentage = valueToPercentage(
      accurateMousePosX,
      accurateMousePosY,
      canvasRef.current.height,
      canvasRef.current.width
    );

    const marker = {
      posX: positionPercentage.posX,
      posY: positionPercentage.posY
    };

    let canAdd = true;
    if (avatarSide.current === "FRONT") {
      canAdd = canAddOnClickedPosition(
        accurateMousePosX,
        accurateMousePosY,
        frontPointersLocation.current
      );
      if (canAdd) {
        frontPointersLocation.current = [
          ...frontPointersLocation.current,
          marker
        ];
        addMarkers(frontPointersLocation.current);
      }
    } else {
      canAdd = canAddOnClickedPosition(
        accurateMousePosX,
        accurateMousePosY,
        backPointersLocation.current
      );
      if (canAdd) {
        backPointersLocation.current = [
          ...backPointersLocation.current,
          marker
        ];
        addMarkers(backPointersLocation.current);
      }
    }

    if (canAdd) {
      onExport({
        front: frontPointersLocation.current,
        back: backPointersLocation.current
      });
    }
  };

  /* To check whether the current clicked position is not same as previously clicked 
    positions 
  */
  const canAddOnClickedPosition = (
    clickedPosX,
    clickedPosY,
    previousMarkersList
  ) => {
    let canAdd = true;
    for (let index = 0; index < previousMarkersList.length; index++) {
      const markerInList = percentageToValue(
        previousMarkersList[index].posX,
        previousMarkersList[index].posY,
        canvasRef.current.height,
        canvasRef.current.width
      );
      //NegativeSide
      // <- toLeft
      const negativePosX = markerInList.posX - pointerImageWidth;
      const negativePosY = markerInList.posY - pointerImageHeight;
      //PositiveSide
      // -> toRight
      const positivePosX = markerInList.posX + pointerImageWidth;
      const positivePosY = markerInList.posY + pointerImageHeight;
      if (
        clickedPosX >= negativePosX &&
        clickedPosX <= positivePosX &&
        clickedPosY >= negativePosY &&
        clickedPosY <= positivePosY
      ) {
        // if condition satisfies it wont alow to add marker
        canAdd = false;
      }
    }
    return canAdd;
  };

  const draw = () => {
    const canvas = canvasRef.current;
    if (avatarSide.current === "FRONT") {
      if (gender === "female") {
        avatarImage.src = FemaleFront;
      } else {
        avatarImage.src = MaleFront;
      }
    } else {
      if (gender === "female") {
        avatarImage.src = FemaleBack;
      } else {
        avatarImage.src = MaleBack;
      }
    }

    avatarImage.onload = () => {
      const parentElement = canvas.parentNode;
      // parentElement.style.height = `${parentElement.offsetHeight - 50}px`;
      // parentElement.style.width = `${parentElement.offsetWidth - 40}px`;
      const size = calculateAspectRatioFit(
        avatarImage.height,
        avatarImage.width,
        parentElement.offsetHeight,
        parentElement.offsetWidth
      );
      // canvasRef.current.style.border = "1px solid grey";
      canvas.height = size.height;
      canvas.width = size.width;
      context.current.clearRect(0, 0, canvas.width, canvas.height);
      context.current.drawImage(
        avatarImage,
        canvas.width / 2 - size.width / 2,
        canvas.height / 2 - size.height / 2,
        size.width,
        size.height
      );

      if (avatarSide.current === "FRONT") {
        if (frontPointersLocation.current.length > 0) {
          addMarkers(frontPointersLocation.current);
        }
      } else {
        if (backPointersLocation.current.length > 0) {
          addMarkers(backPointersLocation.current);
        }
      }
    };
    canvasRef.current.setAttribute(
      "canvasState",
      avatarSide.current === "FRONT" ? "FRONT" : "BACK"
    );
  };

  const addMarkers = (pointersLocation) => {
    pointerImg.src = pointIcon;
    pointerImg.onload = () => {
      for (let i = 0; i < pointersLocation.length; i++) {
        const markerObj = pointersLocation[i];
        const position = percentageToValue(
          markerObj.posX,
          markerObj.posY,
          canvasRef.current.height,
          canvasRef.current.width
        );
        // Draw marker
        context.current.drawImage(
          pointerImg,
          position.posX,
          position.posY,
          pointerImg.width,
          pointerImg.height
        );
      }
    };
  };

  const clearMarkers = () => {
    frontPointersLocation.current = [];
    backPointersLocation.current = [];
    draw();
    onExport({
      front: frontPointersLocation.current,
      back: backPointersLocation.current
    });
  };

  return (
    <article
      ref={symptomsRendererRef}
      className="padding-left-larger padding-right-larger padding-top-larger inherit-parent-width inherit-parent-height"
    >
      <main className="flex-place-children-page-center width-100-percent height-80percentage">
        <canvas
          ref={canvasRef}
          data-cy="symptoms-renderer-canvas"
          canvasState="rendered"
        />
      </main>
      <section className="width-100-percent flex-justify-content-center">
        <button
          data-cy="rotate-avatar-btn"
          className="cursor-pointer display-flex padding-large flex-align-items-center background-color-white font-family-gilroy-medium font-color-secondary font-size-small"
          onClick={() => {
            if (avatarSide.current === "FRONT") {
              avatarSide.current = "BACK";
              draw();
            } else {
              avatarSide.current = "FRONT";
              draw();
            }
          }}
        >
          <span className="margin-right-default display-flex flex-align-items-center ">
            <img
              src={RotateIcon}
              alt="rotateIcon"
              className="height-width-16px"
            />
          </span>
          <span className="display-flex flex-align-items-center">Rotate</span>
        </button>
        {readOnly === false ? (
          <div
            data-cy="clear-markers-btn"
            className="padding-large display-flex flex-align-items-center background-color-white font-family-gilroy-medium font-color-secondary font-size-small cursor-pointer"
            onClick={(event) => {
              clearMarkers(event);
            }}
          >
            <span className="margin-right-default display-flex flex-align-items-center">
              <img src={ClearIcon} alt="clear" className="height-width-16px" />
            </span>
            Clear
          </div>
        ) : null}
      </section>
    </article>
  );
}

SymptomsRenderer.propTypes = {
  /**
   * avatar gender `male` or `female`
   */
  gender: PropTypes.oneOf(["male", "female"]),
  /**
   * location points which you pin on the avatar
   * example format 
   * `{
      front: [
        {
          posX: 42.112518638692876,
          posY: 5.080772387849588
        }
      ],
        back: [
        {
          posX: 38.52828924801187,
          posY: 64.79641219827613
        }
      ]
      }`
   */
  locations: PropTypes.shape({
    front: PropTypes.arrayOf(
      PropTypes.shape({
        posX: PropTypes.number,
        posY: PropTypes.number
      })
    ),
    back: PropTypes.arrayOf(
      PropTypes.shape({
        posX: PropTypes.number,
        posY: PropTypes.number
      })
    )
  }),
  /**
   * disables the pin option on avatar
   */
  readOnly: PropTypes.bool,
  /**
   * callback function which gives location values as arguments
   */
  onExport: PropTypes.func
};

SymptomsRenderer.defaultProps = {
  readOnly: false,
  gender: "male",
  onExport: undefined,
  locations: testPointers
};

export default SymptomsRenderer;
