import React from "react";
import Button from "../../front-end-global-components/components/Button/Button";
import querySubmittedSuccessImage from "../../assets/images/querySubmittedSuccess.svg";
import "./Query.css";

function QuerySuccess(props) {
  return (
    <section className=" inherit-parent-height inherit-parent-width flex-place-children-page-center padding-larger">
      <main className="flex-place-children-page-center">
        <img src={querySubmittedSuccessImage} alt="querySubmittedSuccess" />
        <h1 className="font-weight-normal font-family-gilroy-bold font-size-medium font-color-secondary padding-top-large padding-bottom-large">
          Done! Your feedback is sent.
        </h1>
        <p className="font-family-gilroy-medium font-size-medium font-color-tertiary text-align-center">
          We appreciate your feedback and queries which can help us make Ninto
          even better for future experiences.
        </p>
        <div className="max-width-35-percentage">
          <Button
            buttonKey="query-submit-success-button"
            data-cy="button"
            type="submit"
            text="Okay"
            className="inherit-parent-width margin-top-larger"
            onClick={() => {
              props.navigate("/");
            }}
            boxShadow={false}
          />
        </div>
      </main>
    </section>
  );
}

export default QuerySuccess;
